import React from "react";
import { useForm } from "react-hook-form";
import { BsLinkedin } from "react-icons/bs";
import { FaTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./get-help.scss";

function GetHelp() {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="w-100 bg-background text-textColor h-screen d-flex flex-row align-items-center contact-us-container">
      <div className="left-container px-5">
        <h4>Contact Us</h4>
        <span>
          Need to get in touch with us? Fill out the inquery form or connect
          with us here.
        </span>
        <div className="social-container d-flex flex-row align-items-center gap-2 mt-4">
          <Link
            to="https://www.linkedin.com/in/neha-mehta-532772290/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsLinkedin className="fs-5" />
          </Link>
          <Link
            to="https://twitter.com/MedtrainX"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="fs-5" />
          </Link>
        </div>
      </div>
      <div className="right-container w-50 pe-5">
        <form
          className=" contact-us-form bg-backgroundModal p-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="d-flex flex-column w-100 mb-3">
            <div className="d-flex flex-row gap-2">
              {/* <label htmlFor="name" className='w-25'>Name</label> */}
              <input
                className="flex-grow-1 placeholder-textColor  bg-modalInputBg px-2 py-2"
                placeholder="Name"
                type="text"
                id="name"
                name="name"
                {...register("name", { required: true })}
              />
            </div>
            {errors?.name && <span>This field is required</span>}
          </div>

          <div className="d-flex flex-column w-100 mb-3">
            <div className="d-flex flex-row w-100 gap-2">
              {/* <label htmlFor="email" className='w-25'>Email</label> */}
              <input
                className="flex-grow-1 placeholder-textColor  bg-modalInputBg px-2 py-2"
                placeholder="Email ID"
                type="email"
                id="email"
                name="email"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
            </div>
            {errors?.email && errors?.email?.type === "required" && (
              <span>This field is required</span>
            )}
            {errors?.email && errors?.email?.type === "pattern" && (
              <span>Invalid email format</span>
            )}
          </div>

          <div className="d-flex flex-column w-100 mb-3">
            <div className="d-flex flex-column w-100 gap-2">
              {/* <label htmlFor="message">Message</label> */}
              <textarea
                className="flex-grow-1 placeholder-textColor bg-modalInputBg px-2 py-2"
                placeholder="Message"
                id="message"
                name="message"
                {...register("message", { required: true })}
              />
            </div>
            {errors?.message && <span>This field is required</span>}
          </div>

          <div className="d-flex flex-column w-100 mb-3">
            <div className="d-flex flex-row w-100 gap-2">
              {/* <label htmlFor="contactNumber" className='w-25'>Contact Number</label> */}
              <input
                className="flex-grow-1 placeholder-textColor bg-modalInputBg py-2 px-2"
                placeholder="Contact Number"
                type="tel"
                id="contactNumber"
                name="contactNumber"
                {...register("contactNumber", {
                  required: true,
                  pattern: /^[0-9]{10}$/,
                })}
              />
            </div>
            {errors?.contactNumber &&
              errors?.contactNumber?.type === "required" && (
                <span>This field is required</span>
              )}
            {errors?.contactNumber &&
              errors?.contactNumber?.type === "pattern" && (
                <span>Invalid contact number format</span>
              )}
          </div>

          <button
            className="btn-submit bg-buttonBg fs-12 w-auto "
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default GetHelp;
