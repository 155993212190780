import React from "react";

const More = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      fill={color}
      viewBox="0 -960 960 960"
      width="40"
    >
      <path d="M257.731-185.731v-364.923h28.923v336h336v28.923H257.731Zm152-152v-364.923h28.923v336h336v28.923H409.731Z" />
    </svg>
  );
};

export default More;
