import api from '../config/axios';

const createManikinType = (data) => {
  return api.post(`/device/manikin-type`, data);
}
const createManikinSubType = (typeId, data) => {
  return api.post(`/device/manikin-sub-type/${typeId}`, data);
}
const createManikin = (data) => {
  return api.post(`/device/manikin`, data);
}
const createSensorLocation = (data) => {
  return api.post(`/device/sensor-location`, data);
}
const getManikinTypes = () => {
  return api.get(`/device/manikin-type`);
}
const getManikinSubTypes = () => {
  return api.get(`/device/manikin-sub-type`);
}
const getManikins = () => {
  return api.get(`/device/manikins`);
}
const getManikinDetails = (deviceId) => {
  return api.get(`/device/manikin/${deviceId}`);
}
const getDevicesBySubTypeId = (subTypeId) => {
  return api.get(`/device/get-devices/${subTypeId}`);
}
const getSensorLocations = () => {
  return api.get(`/device/sensor-locations`);
}
const updateManikinType = (typeId, data) => {
  return api.put(`/device/manikin-type/${typeId}`, data);
}
const updateManikinSubType = (typeId, subTypeId, data) => {
  return api.put(`/device/manikin-sub-type/${typeId}/${subTypeId}`, data);
}
const updateManikin = (deviceId, data) => {
  return api.put(`/device/manikin/${deviceId}`, data);
}
const updateSensorLocation = (locationId, data) => {
  return api.put(`/device/sensor-location/${locationId}`, data);
}
const deleteManikin = (deviceId) => {
  return api.delete(`/device/manikin/${deviceId}`);
}
const deleteSensorLocation = (locationId) => {
  return api.delete(`/device/sensor-location/${locationId}`);
}


export {
  createManikinType,
  createManikinSubType,
  createManikin,
  createSensorLocation,
  getManikinTypes,
  getManikinSubTypes,
  getManikins,
  getManikinDetails,
  getDevicesBySubTypeId,
  getSensorLocations,
  updateManikinType,
  updateManikinSubType,
  updateManikin,
  updateSensorLocation,
  deleteManikin,
  deleteSensorLocation,

}