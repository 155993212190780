import React, { useState, useEffect } from "react";
import RightArrow from "../../components/icons/RightArrow";
import logo from "../../assets/images/login/medilogo.png";
import AddIcon from "../../components/icons/AddIcon";
import CustomModal from "../../components/common/CustomModal";
import { getAssignedTest } from "../../api/instructor.rest";
import AssignTestModal from "../../components/common/AssignTestModal";
import TooltipUi from "../../components/common/TooltipUi";

const AssignedAllTestData = () => {
  const [modalHide, setModalHide] = useState(false);
  const [assignedTestData, setAssignedTestData] = useState([]);
  const [testData, setTestData] = useState({});
  const [testInd, setTestInd] = useState(null);

  const getAllTestData = async () => {
    try {
      const res = await getAssignedTest("all");
      console.log("res?.data?.data111111111", res?.data?.data);
      setAssignedTestData(res?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllTestData();
  }, []);

  const onClose = () => {
    setModalHide(false);
  };

  //   const formatDate=(date)=>{
  //     const fdate = new Date(date);
  //     const formattedDate = fdate.toLocaleDateString();
  //     return formattedDate
  // }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleModalClick = (data, tIndex) => {
    setTestInd(tIndex);
    setTestData(data);
    setModalHide(true);
  };

  const contentToHtml = (text) => {
    return text
      ?.split("\n\n")
      .map((paragraph) => {
        // Check if the paragraph starts with a number followed by a dot and a space
        if (/^\d+\.\s/.test(paragraph)) {
          // Extract the number, heading, and description
          const matches = paragraph.match(/^(\d+)\.\s(.*?):\s(.*)$/);

          if (matches) {
            // Extract the parts
            const [, number, heading, description] = matches;

            // Wrap the heading in <strong> tags
            const boldHeading = `<strong>${number}. ${heading}:</strong>`;

            // Return the formatted HTML
            return `<p>${boldHeading} ${description}</p>`;
          }
        } else {
          // For regular paragraphs, just wrap in <p> tags
          return `<p>${paragraph}</p>`;
        }
      })
      .join("");
  };

  return (
    <div className="w-[100vw-60px] h-[100vh] overflow-y-scroll bg-[var(--bg-primary)]">
      <div className="z-40 w-max h-max flex p-2 gap-1">
        <div className="manikin-cont w-[20px] h-[20px]">
          <img
            src={logo}
            alt="logo"
            className="bg-[var(--text-primary-color)] rounded-full"
          />
        </div>
        <div className="w-max text-sm font-normal text-[#787878]">
          MedTrain AI
        </div>
      </div>

      <div className="px-4 py-4">
        <div className="w-100 flex justify-end text-textColor text-[1.3rem] font-semibold">
          Test Sessions
        </div>
        <div className=" h-[100%] grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 pt-4  place-items-center overflow-y-scroll">
          {assignedTestData?.map((test, i) => {
            return (
              <div className="flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[17rem] p-2 bg-[var(--secondary-color)]">
                <div className="w-[100%] h-max flex flex-col gap-2">
                  <div className="pb-2">
                    <div className="font-extrabold text-base uppercase cursor-pointer">
                      {test?.assessment_name}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-xs">Debriefing</div>
                    <div className="font-extrabold text-ellipsis line-clamp-3 text-xs">
                      {test?.assessmentMap[0]?.sessionBriefing?.debriefing.replace(
                        /<[^>]*>/g,
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div>
                                <div className='font-normal text-xs'>Manikin name:</div>
                                <div className='font-normal '>{test?.masterDevice?.device_name}</div>
                            </div> */}
                  <div className="flex flex-col gap-2">
                    <div className=" font-semibold text-xs">Last Test On:</div>
                    <div className="font-extrabold">
                      {formatDate(test?.assessmentMap[0]?.test_start)}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-xs">
                      Last Test Score:
                    </div>
                    <div className="font-extrabold ">
                      {Number(
                        test?.assessmentMap[0]?.sessionBriefing?.adjusted_score
                      ).toFixed(1)}
                    </div>
                  </div>
                  <div>
                    {/* <div>Last Test Score</div>
                                <div>{stdDetails['Last Test Score']}</div> */}
                  </div>
                </div>

                <div className="w-[100%] h-max flex justify-end items-center gap-4">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleModalClick(assignedTestData[i], i)}
                  >
                    <TooltipUi
                      name="Details"
                      icons={<RightArrow />}
                      width="max"
                      placement="bottom"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <AssignTestModal
          heading={"Assign a test"}
          handleClose={onClose}
          open={modalHide}
        >
          {testData?.assessmentMap?.map((testDet, i) => {
            return (
              <div className="w-[500px] h-[100vh] flex flex-col gap-2 overflow-y-scroll px-4 py-4">
                <div className="pb-2">
                  <div className="font-medium text-base uppercase">
                    Test Session {testInd + 1}
                  </div>
                </div>
                <div>
                  <div className="font-normal text-xs">Debriefing</div>
                  <div className="font-normal text-xs">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: testDet?.sessionBriefing?.debriefing,
                      }}
                    ></div>
                  </div>
                </div>
                <div>
                  <div className="font-normal text-xs">Test On:</div>
                  <div className="font-normal ">
                    {formatDate(testDet?.test_start)}
                  </div>
                </div>
                <div>
                  <div className="font-normal text-xs">Test Score</div>
                  <div className="font-normal ">
                    {Number(testDet?.sessionBriefing?.adjusted_score).toFixed(
                      1
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </AssignTestModal>
      </div>
    </div>
  );
};

export default AssignedAllTestData;
