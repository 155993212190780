import React, { useEffect, useState } from "react";
import logo from "../../assets/images/login/logo_new.png";
import AddIcon from "../../components/icons/AddIcon";
import CustomModal from "../../components/common/CustomModal";
import AssignmentAdd from "../../components/icons/AssignmentAdd";
import RightArrow from "../../components/icons/RightArrow";
import { getUsersByType } from "../../api/user.rest";
import AssignTestModal from "../../components/common/AssignTestModal";
import { newUserCreate } from "../../api/user.rest";
import { toast } from "react-toastify";
import TooltipUi from "../../components/common/TooltipUi";
import AssignmentForm from "./AssignmentForm";
import { useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";

const Student = () => {
  const userType = localStorage.getItem("userType");
  const [modalHide, setModalHide] = useState(false);
  const [allUserData, setAllUserData] = useState([]);
  const orgId = localStorage.getItem("orgId");
  const [stdName, setStdName] = useState("");
  const [selectedStid, setSelectedStid] = useState([]);
  const [modalHide1, setModalHide1] = useState(false);
  const [uNameErrMsg, setUNameErrMsg] = useState("");
  const [unmErrMsg, setUnmErrMsg] = useState("");
  const [pswErrMsg, setPswErrMsg] = useState("");
  const [addErrMsg, setAdderrMsg] = useState("");
  const [mobErrMsg, setMobErrMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [unm, setUnm] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAdderss] = useState("");
  const [mobNumber, setMobNumber] = useState("");
  const navigate = useNavigate();

  const onClose = () => {
    setModalHide(false);
  };

  const onClose1 = () => {
    setModalHide1(false);
  };

  const getAllusersByType = async () => {
    try {
      const res = await getUsersByType();
      setAllUserData(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllusersByType();
  }, []);

  const handleAddStudentClick = () => {
    setModalHide(true);
  };

  const handleAssignTest = (stdid, stdname) => {
    setStdName(stdname);
    setSelectedStid([stdid]);
    setModalHide1(true);
  };

  const userValid = () => {
    let checked = true;

    if (userName === "") {
      checked = false;
      setUNameErrMsg("enter userName");
    } else {
      setUNameErrMsg("");
    }
    if (unm === "") {
      checked = false;
      setUnmErrMsg("enter userName");
    } else {
      setUnmErrMsg("");
    }
    if (password === "") {
      checked = false;
      setPswErrMsg("enter password");
    } else {
      setPswErrMsg("");
    }
    if (address === "") {
      checked = false;
      setAdderrMsg("enter address");
    } else {
      setAdderrMsg("");
    }
    if (mobNumber === "") {
      checked = false;
      setMobErrMsg("enter mobile number");
    } else {
      setMobErrMsg("");
    }
    return checked;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const createUser = async () => {
    const data = {
      utype_id: "ut_BqQIV1DVL",
      name: userName,
      Unm: unm,
      password: password,
      Uaddress: address,
      umob: mobNumber,
      org_id: orgId,
      uorg_idcard: "",
    };
    try {
      const res = await newUserCreate(data);
      console.log("sss", res?.status);
      if (res?.status === 200) {
        toast.success("new user created");
        getAllusersByType();
        setModalHide(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.data);
      }
    }
  };

  const onUserSubmit = () => {
    if (userValid()) {
      createUser();

      setUserName("");
      setUnm("");
      setPassword("");
      setAdderss("");
      setMobNumber("");
    }
    setModalHide(true);
  };

  useEffect(() => {
    if (modalHide === false) {
      setUserName("");
      setUnm("");
      setPassword("");
      setAdderss("");
      setMobNumber("");
    }
  }, [modalHide]);

  const modalCallBack = (modal) => {
    setModalHide1(modal);
  };

  useEffect(() => {
    modalCallBack();
  }, []);

  const handleNavigateClick = (id, unm) => {
    navigate(`/playAndLearn?uId=${id}&uNm=${unm}`);
  };

  return (
    <div className="w-[100vw-60px] h-[100vh] bg-background overflow-y-scroll">
      <div className="z-40 w-max h-max flex items-center p-2 gap-1">
        <div className="manikin-cont w-[100px] h-[20px]">
          <img src={logo} alt="logo" className="" />
        </div>
        {/* <div className="w-max text-xl font-normal text-[var(--text-primary-color)]">
          MedTrain AI
        </div> */}
      </div>

      <div className="px-4 pt-4">
        <div className="flex justify-end gap-3 items-center">
          <div className="font-extrabold text-headingTxt2 text-2xl">
            Students
          </div>
          <div
            className="border-[0.1px]  text-textColor flex justify-center items-center"
            onClick={handleAddStudentClick}
          >
            <IoMdAdd />
          </div>
        </div>

        <AssignTestModal
          heading={"Create User"}
          handleClose={onClose}
          open={modalHide}
        >
          <div className="p-4 font-extrabold text-headingTxt2 text-2xl">
            Create Student
          </div>
          <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="userName"
                    className="font-medium text-lg text-textColor"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id=""
                    placeholder="Enter Name"
                    className="border-b-[0.1px] text-textColor bg-modalInputBg focus:outline-none font-medium px-2 py-2 focus-within:border-b-[2px] focus-within:border-[var(--text-secondary-color)]"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                    name="userName"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {userName === "" ? uNameErrMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="unm"
                    className="font-medium text-lg text-textColor"
                  >
                    UserName
                  </label>
                  <input
                    type="text"
                    id=""
                    placeholder="Enter UserName"
                    className="border-b-[0.1px] text-textColor bg-modalInputBg focus:outline-none font-medium px-2 py-2 focus-within:border-b-[2px] focus-within:border-[var(--text-secondary-color)]"
                    onChange={(e) => setUnm(e.target.value)}
                    value={unm}
                    name="unm"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {unm === "" ? unmErrMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="password"
                    className="font-medium text-lg text-textColor"
                  >
                    Password
                  </label>
                  <input
                    type="text"
                    id=""
                    placeholder="Enter Password"
                    className="border-b-[0.1px] text-textColor bg-modalInputBg focus:outline-none font-medium px-2 py-2 focus-within:border-b-[2px] focus-within:border-[var(--text-secondary-color)]"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    name="password"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {password === "" ? pswErrMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="address"
                    className="font-medium text-lg text-textColor"
                  >
                    Address
                  </label>
                  <textarea
                    id=""
                    placeholder="Enter Your Address"
                    className="border-b-[0.1px] text-textColor bg-modalInputBg focus:outline-none font-medium p-2 py-2 focus-within:border-b-[2px] focus-within:border-[var(--text-secondary-color)]"
                    onChange={(e) => setAdderss(e.target.value)}
                    value={address}
                    name="address"
                  ></textarea>
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {address === "" ? addErrMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="mobNumber"
                    className="font-medium text-lg text-textColor"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id=""
                    placeholder="Enter Mobile number"
                    className="border-b-[0.1px] text-textColor bg-modalInputBg focus:outline-none font-medium px-2 py-2 focus-within:border-b-[2px] focus-within:border-[var(--text-secondary-color)]"
                    onChange={(e) => setMobNumber(e.target.value)}
                    value={mobNumber}
                    name="mobNumber"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {mobNumber === "" ? mobErrMsg : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="w-max py-[0.7rem] px-[0.7rem] text-textColor transition-all duration-200 hover:cursor-pointer font-medium"
                onClick={() => setModalHide(false)}
              >
                Cancel
              </div>
              <div
                className="w-max p-[0.7rem] bg-[#00b0f0] transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={onUserSubmit}
              >
                Submit
              </div>
            </div>
          </form>
        </AssignTestModal>

        <div className=" h-[100%] grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 py-4  place-items-center overflow-y-scroll">
          {allUserData?.map((stdDetails, i) => {
            return (
              <div className="flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[18rem] p-2 bg-[var(--secondary-color)]">
                <div className="w-[100%] h-max flex p-2 flex-col gap-2">
                  <div className="pb-2">
                    <div className="font-semibold text-headingTxt2 text-base">
                      {stdDetails?.name}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold text-textColor text-xs">
                      Total tets taken:
                    </div>
                    <div className="font-extrabold text-textColor ">
                      {stdDetails?.assessmentCount}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold text-textColor text-xs">
                      Last Test On:
                    </div>
                    <div className="font-extrabold text-textColor ">
                      {stdDetails?.userLastAssessment === null
                        ? "05 Jan, 2024"
                        : formatDate(
                            stdDetails?.userLastAssessment?.last_test_start_date
                          )}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold text-textColor text-xs">
                      Last Test Score:
                    </div>
                    <div className="font-extrabold text-textColor ">
                      {stdDetails?.userLastAssessment?.assessment_score}
                    </div>
                  </div>
                  <div></div>
                </div>
                {userType === "instructor" && (
                  <div className="w-[100%] h-max flex justify-end items-center gap-4">
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleAssignTest(stdDetails?.uid, stdDetails?.name)
                      }
                    >
                      <TooltipUi
                        name="Assignment"
                        icons={<AssignmentAdd />}
                        width="max"
                        placement="bottom"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleNavigateClick(stdDetails?.uid, stdDetails?.name)
                      }
                    >
                      <TooltipUi
                        name="Dashboard"
                        icons={<RightArrow />}
                        width="max"
                        placement="bottom"
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {allUserData && (
          <AssignTestModal
            heading={"Assign a Test"}
            handleClose={onClose1}
            open={modalHide1}
          >
            <div className="p-4 font-extrabold text-headingTxt2">
              Assign a test
            </div>
            <AssignmentForm
              modalCallBack={modalCallBack}
              allUserData={allUserData}
              selectedStid={selectedStid}
              modalHide1={modalHide1}
            />
          </AssignTestModal>
        )}
      </div>
    </div>
  );
};

export default Student;
