import React from "react";

const AssignmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
      fill="var(--svgIconColor)"
      stroke="var(--svgIconColor)"
    >
      <path
        d="M250-200.5q-20.669 0-35.084-14.416Q200.5-229.331 200.5-250v-460q0-20.669 14.416-35.084Q229.331-759.5 250-759.5h198q-17.5-23.5-6-47.5t38-24q27.5 0 39 24t-7 47.5h198q20.669 0 35.084 14.416Q759.5-730.669 759.5-710v460q0 20.669-14.416 35.084Q730.669-200.5 710-200.5H250Zm0-17.5h460q12 0 22-10t10-22v-460q0-12-10-22t-22-10H250q-12 0-22 10t-10 22v460q0 12 10 22t22 10Zm66.5-111h232v-17.5h-232v17.5Zm0-142.5h327V-489h-327v17.5Zm0-142.5h327v-17.5h-327v17.5ZM480-755q10.4 0 17.2-6.8 6.8-6.8 6.8-17.2 0-10.4-6.8-17.2-6.8-6.8-17.2-6.8-10.4 0-17.2 6.8-6.8 6.8-6.8 17.2 0 10.4 6.8 17.2 6.8 6.8 17.2 6.8ZM218-218v-524 524Z"
        fill="var(--svgIconColor)"
        stroke="var(--svgIconColor)"
      />
    </svg>
  );
};

export default AssignmentIcon;
