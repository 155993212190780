import api from '../config/axios';

const mapManikinToOrg = (orgId, deviceId) => {
  return api.post(`/device/manikin-allocation/${orgId}/${deviceId}`);
};
const mapManikinToOrgUser = (orgId, data) => {
  return api.post(`/device/manikin-user-allocation/${orgId}`, data);
};
const mapControllerToSensor = (sensorId, data) => {
  return api.post(`/device/sensor-controller-allocation/${sensorId}`, data);
};
const mapManikinToController = (contId, data) => {
  return api.post(`/device/manikin-controller-allocation/${contId}`, data);
};
const removeManikinFromOrg = (orgId, deviceId) => {
  return api.delete(`/device/manikin-allocation/${orgId}?deviceId=${deviceId}`);
};
const removeManikinFromOrgUser = (allocId) => {
  return api.delete(`/device/manikin-user-allocation/${allocId}`);
};
const removeControllerFromSensor = (sensorId) => {
  return api.delete(`/device/sensor-controller-allocation/${sensorId}`);
};
const removeManikinFromController = (contId) => {
  return api.delete(`/device/manikin-controller-allocation/${contId}`);
};
const getOrgManikins = (orgId) => {
  return api.get(`/device/manikins?orgId=${orgId}`);
};
const getManikinOrgUsers = (deviceId) => {
  return api.get(`/device/manikin-user-allocation/${deviceId}`);
};
const getAllManikinsList = (deviceId) => {
  return api.get(`/device/all-manikin-allocation`);
};
const getErrorCodes = (data) => {
  return api.post(`/errorcode/get`,data);
};
const getErrorCodesCreate = (data) => {
  return api.post(`/errorcode`,data);
};
const getErrorCodesEdit = (id,data) => {
  return api.put(`/errorcode/${id}`,data);
};

export {
  mapManikinToOrg,
  mapManikinToOrgUser,
  mapControllerToSensor,
  mapManikinToController,
  removeManikinFromOrg,
  removeManikinFromOrgUser,
  removeControllerFromSensor,
  removeManikinFromController,
  getOrgManikins,
  getManikinOrgUsers,
  getAllManikinsList,
  getErrorCodes,
  getErrorCodesCreate,
  getErrorCodesEdit,
};
