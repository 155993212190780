import React, { useState, useEffect } from "react";
import { createAssessment } from "../../api/instructor.rest";
import { toast } from "react-toastify";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import { getOrgManikins } from "../../api/manikin.rest";

const AssignmentForm = (allUserData, modalCallBack, getStudentListData) => {
  const getNextSevenDaysDate = () => {
    const today = new Date();
    const nextThreeDays = new Date(today);
    nextThreeDays.setDate(today.getDate() + 7); // Add seven days to today's date
    return nextThreeDays.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  };

  // Get the date three days from today
  const defaultDate = getNextSevenDaysDate();

  const today = new Date().toISOString().split("T")[0];
  const [assessment1, setAssessment1] = useState("");
  const [mnkId1, setMnkId1] = useState("");
  const [startDate1, setStartDate1] = useState(today);
  const [dueDate1, setDueDate1] = useState(defaultDate);
  const [uidErrMsg, setUidErrMsg] = useState("");
  const [assNameErrMsg, setAssNameErrMsg] = useState("");
  const [mnkIdErrMsg, setMnkIdErrMsg] = useState("");
  const [stdErrMsg, setstdErrMsg] = useState("");
  const [endErrMsg, setendErrMsg] = useState("");
  const [manikinDetails, setManikinDetails] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const orgId = localStorage.getItem("orgId");
  const [modalHide1, setModalHide1] = useState(false);

  useEffect(() => {
    setSelectedNames(allUserData?.selectedStid);
  }, []);

  const valid = () => {
    let checked = true;

    if (selectedNames.length === 0) {
      checked = false;
      setUidErrMsg("select student");
    } else {
      setUidErrMsg("");
    }
    if (assessment1 === "") {
      checked = false;
      setAssNameErrMsg("enter assessment name");
    } else {
      setAssNameErrMsg("");
    }
    if (mnkId1 === "") {
      checked = false;
      setMnkIdErrMsg("select mainikin");
    } else {
      setMnkIdErrMsg("");
    }
    if (startDate1 === "") {
      checked = false;
      setstdErrMsg("enter start date");
    } else {
      setstdErrMsg("");
    }
    if (dueDate1 === "") {
      checked = false;
      setendErrMsg("enter due date");
    } else {
      setendErrMsg("");
    }

    return checked;
  };

  const getManikinListData = async (OrgId) => {
    const res = await getOrgManikins(OrgId);
    setManikinDetails(res.data?.data);
  };

  useEffect(() => {
    getManikinListData(orgId);
  }, []);

  const apiCall = async () => {
    const data = {
      assessmentName: assessment1,
      mnkId: mnkId1,
      uid: selectedNames,
      start_date: startDate1,
      due_date: dueDate1,
    };
    try {
      const res = await createAssessment(data);
      if (res?.status === 200) {
        toast.success("Assessment Created");
        allUserData.getStudentListData();
        setModalHide1(false);
        allUserData.modalCallBack(false);
      }
    } catch (err) {
      console.log("assesmet errro");
      // if (err?.response?.status===400) {
      //     toast.error(err?.response?.data?.data)
      // }
    }
  };

  const onSubmit = () => {
    if (valid()) {
      setModalHide1(false);
      allUserData.modalCallBack(false);
      apiCall();

      setAssessment1("");
      setMnkId1("");
      setStartDate1("");
      setDueDate1("");
    }
    setModalHide1(true);
    allUserData.modalCallBack(true);
  };

  useEffect(() => {
    if (allUserData.modalHide1 === false) {
      setAssessment1("");
      setMnkId1("");
      setStartDate1("");
      setDueDate1("");
    }
  }, [allUserData.modalHide1]);

  return (
    <form className="flex w-max h-max flex-col gap-4 px-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col justify-start">
          <div className="flex flex-col justify-start gap-2">
            <label
              htmlFor="userName"
              className="font-medium text-textColor text-[0.8rem]"
            >
              Test Name
            </label>
            <input
              type="text"
              id=""
              placeholder="Type a name"
              className="outline-none transition duration-300 border-b-2 focus:border-inputBorderColor p-2 bg-modalInputBg placeholder-textColor "
              onChange={(e) => setAssessment1(e.target.value)}
              value={assessment1}
              name="assessmentName"
            />
          </div>
          <div className="text-[0.8rem] text-red-500">
            {assessment1 === "" ? assNameErrMsg : ""}
          </div>
        </div>
        <div className="w-[100%]">
          {/* <label htmlFor="dropDown" className='font-medium text-[0.8rem]'>Select a Manikin</label>
                        <select id="dropDown" placeholder='- Select -' className='bg-transparent border-b-[0.1px] focus:outline-none'
                            onChange={(e) => setMnkId1(e.target.value)}
                            value={mnkId1}
                            name="mnkId"
                        >
                            <option value="" hidden></option>
                            {
                                manikinDetails?.map((data, i) => {
                                    return (<option value={data?.device_id} key={i} className='text-black'>{data?.device_name}</option>)
                                })
                            }
                        </select> */}

          <FormControl sx={{ width: 500, color: "var(--text-primary-color)" }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ color: "var(--text-primary-color)" }}
            >
              Select a Manikin
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className=" text-textColor bg-modalInputBg"
              value={mnkId1}
              label="Select a Manikin"
              onChange={(e) => setMnkId1(e.target.value)}
            >
              {manikinDetails?.map((data, i) => {
                return (
                  <MenuItem
                    value={data?.device_id}
                    key={i}
                    className="text-black"
                  >
                    {data?.device_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className="text-[0.8rem] text-red-500">
            {mnkId1 === "" ? mnkIdErrMsg : ""}
          </div>
        </div>
        <div className="flex flex-col justify-start">
          <FormControl sx={{ width: 500, color: "var(--text-primary-color)" }}>
            <InputLabel sx={{ color: "var(--text-primary-color)" }}>
              Select Students
            </InputLabel>
            <Select
              defaultValue={selectedNames}
              sx={{ color: "var(--text-primary-color)" }}
              multiple
              value={selectedNames}
              onChange={(e) => setSelectedNames(e.target.value)}
              input={<OutlinedInput label="Multiple Select" />}
            >
              {allUserData?.allUserData?.map((std, i) => (
                <MenuItem key={i} value={std.uid}>
                  {std.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="text-[0.8rem] text-red-500">
            {selectedNames?.length === 0 ? uidErrMsg : ""}
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col">
            <div className="flex flex-col justify-start gap-2">
              <label
                htmlFor="Start Date"
                className="font-medium text-[0.8rem] text-textColor"
              >
                Start Date
              </label>
              <input
                type="date"
                id="Start Date"
                min={today}
                className="border-b-[0.1px] bg-modalInputBg text-textColor p-2 focus:outline-none"
                onChange={(e) => setStartDate1(e.target.value)}
                value={startDate1}
                name="start_date"
              />
            </div>
            <div className="text-[0.8rem] text-red-500">
              {startDate1 === "" ? stdErrMsg : ""}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col justify-start gap-2">
              <label
                htmlFor="end Date"
                className="font-medium text-[0.8rem] text-textColor"
              >
                End Date
              </label>
              <input
                type="date"
                id="end Date"
                min={today}
                className="border-b-[0.1px] bg-modalInputBg text-textColor p-2 focus:outline-none"
                onChange={(e) => setDueDate1(e.target.value)}
                value={dueDate1}
                name="due_date"
              />
            </div>
            <div className="text-[0.8rem] text-red-500">
              {dueDate1 === "" ? endErrMsg : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 pt-4">
        <div
          className="hover:cursor-pointer p-2 text-textColor"
          onClick={() => allUserData.modalCallBack(false)}
        >
          Cancel
        </div>
        <div
          className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
          onClick={onSubmit}
        >
          Assign Test
        </div>
      </div>
    </form>
  );
};

export default AssignmentForm;
