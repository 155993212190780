import React from "react";

function PauseDebriefIcon() {
  return (
    <div className="flex items-center">
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="w-[30px] h-[30px]"
        fill="#3e3e3e"
      >
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
      </svg>
      <span className="whitespace-nowrap text-xl text-center pl-2 pr-3">Pause</span>
    </div>
  );
}

export default PauseDebriefIcon;
