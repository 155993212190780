import React from "react";

const PlayLearn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
      fill="var(--svgIconColor)"
      stroke="var(--svgIconColor)"
    >
      <path d="M142.154-546.346v-150.74q0-25.083 16.864-39.383 16.864-14.301 41.318-14.301h559.455q24.454 0 41.255 14.301 16.8 14.3 16.8 39.383v150.74h-28.923v-150.885q0-9.231-7.692-16.923-7.692-7.692-16.923-7.692H195.692q-9.231 0-16.923 7.692-7.692 7.692-7.692 16.923v150.885h-28.923Zm58.055 257.115q-24.454 0-41.255-14.3-16.8-14.301-16.8-39.238v-174.654h28.923v174.654q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692h568.616q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-174.654h28.923v174.654q0 24.937-16.864 39.238-16.864 14.3-41.318 14.3H200.209ZM84.923-212.923v-28.923h790.154v28.923H84.923ZM480-520.192Zm-337.846 2.769v-28.923h186.638q8.323 0 13.958 3.807 5.635 3.808 9.29 9.506l57.845 111.417 128.826-212.916q2.366-4.314 5.607-5.045 3.242-.731 6.519-.731 3.278 0 6.105-.269 2.827-.269 5.135.731l66.885 93.5h188.884v28.923H636.671q-8.696 0-16.395-3.981t-13.545-10.058L554-611.615 420.346-392.692q-2.815 2.884-5.48 5.077-2.665 2.192-5.711 2.192-4.236 0-7.888-2.115-3.652-2.116-6.318-4.975l-67.526-124.91H142.154Z" />
    </svg>
  );
};

export default PlayLearn;
