import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login, profile } from "../../api/user.rest";
import styles from "../../components/common/sysadminlogin.module.scss";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showStatelogin } from "../../components/slice/loginstate";
import { useDarkMode } from "../../hooks/useDarkMode";
function SysAdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    isSubmited: false,
  });
  const [showPassword, setShowpassword] = useState(false);
  const [randomNumber, setRandomNumber] = useState(559922);

  const generateRandomNumber = () => {
    const newRandomNum = Math.floor(Math.random() * 900000) + 100000;

    setRandomNumber(newRandomNum);
  };

  const [errorMesg, SetErrorMesg] = useState("");
  const [pswErrMesg, setPswErrMesg] = useState("");
  const [status, setStatus] = useState("");

  const onReset = () => {
    setFormData({
      userName: "",
      password: "",
    });
  };
  /*
   * Input
   * Event Handller
   */
  const inputHandler = (eve, type) => {
    const txt = eve.target.value;
    if (type === "name") {
      setFormData({
        ...formData,
        userName: txt,
      });
    }
    if (type === "password") {
      setFormData({
        ...formData,
        password: txt,
      });
    }
  };

  /*
   * Input
   * Event Handller
   */
  const valid = () => {
    let checked = true;
    const { userName, password } = formData;
    if (userName === "") {
      checked = false;
      SetErrorMesg("Enter UID");
    }
    if (password === "") {
      checked = false;
      setPswErrMesg("Enter Password");
    }

    return checked;
  };

  /*
   * API
   * Login
   */
  const apiLogin = async () => {
    const { userName, password } = formData;
    const data = {
      username: userName,
      password: password,
    };
    try {
      // const res = await login('root' || 'admin' || 'user', data);

      const res = await login("root", data);
      console.log(res);
      if (res?.status === 200) {
        const token = res.data?.data?.token;
        const orgId = res.data?.data?.userDetails?.org_id;
        localStorage.setItem("token", token);
        localStorage.setItem("orgId", orgId);
        dispatch(showStatelogin());
        setTimeout(() => {
          myProfile();
        }, 1000);
        localStorage.setItem("userNm", userName);
        toast.success("login successful");
      }
    } catch (ex) {
      // console.log("sererre",ex.response.status);
      if (ex?.response?.status === 400) {
        toast.error("invalid credentials");
      }
      if (ex?.response?.data?.status === "fail") {
        try {
          const res = await login("admin", data);
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          myProfile();
        } catch (ex) {
          setStatus(ex?.response?.data?.status);
          SetErrorMesg(ex.response.data.data);
          setPswErrMesg(ex.response.data.data);
          // if(ex.response.data.status === 'fail'){
          if (ex?.response?.data?.status === "fail") {
            try {
              // const res = await login('root' || 'admin' || 'user', data);

              const res = await login("user", data);
              const token = res.data.data.token;
              localStorage.setItem("token", token);
              myProfile();
            } catch (ex) {
              console.log(ex);
              // if(ex.response.data.status === 'fail'){

              // }
            }
          }
          // }
        }
      }
    }
  };
  /*
   * API
   * My Profile
   */
  const myProfile = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };

    axios
      .get(`${process.env?.REACT_APP_BASE_URL}/me`, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.data.role.utype_name);
        localStorage.setItem("userType", response.data.data.role.utype_name);
        if (response.data.data.role.utype_name === "root") {
          navigate("/manikin");
        }
        if (response.data.data.role.utype_name === "user") {
          navigate("/playAndLearn");
        }
        if (response.data.data.role.utype_name === "instructor") {
          navigate("/student");
        }
        if (response.data.data.role.utype_name === "admin") {
          navigate("/instructor");
        }
        // navigate("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*
   * Form
   * Submitted
   */
  const submitted = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      isSubmited: true,
    });
    if (valid()) {
      apiLogin();
    }

    if (formData.userName === "") {
      SetErrorMesg("Enter UID");
    } else if (formData.password === "") {
      setPswErrMesg("Enter Password");
    }
  };

  const handleShowpassword = () => [setShowpassword(!showPassword)];

  return (
    // <div className={styles.sysAdmin_cont} style={{background:"red"}} >
    //     {/* <h5 className='form-header'>Login</h5> */}
    //     {/* <form> */}

    //     <div className={styles.inp_Container}>
    //         <input type="text" className={styles.inputfield}/>
    //     </div>

    //     {/* <input type="text"
    //         style={formData.isSubmited && formData.userName === '' ? { border: "1px solid red", borderRadius: "4px" } : {}}
    //         onChange={(e) => inputHandler(e, 'name')}
    //         name='userName'
    //         className="login-input"
    //         placeholder='Username' />
    //     <input type="password"
    //         style={formData.isSubmited && formData.password === '' ? { border: "1px solid red", borderRadius: "4px" } : {}}
    //         onChange={(e) => inputHandler(e, 'password')}
    //         name='password'
    //         className="login-input mb-0"
    //         placeholder='Password' /> */}
    //     {/* <div className="text-end m-b-15"><Link to="#" className='fs-12 c-primary'>Forget Password</Link></div>
    //     <button className="btn-submit m-b-25" onClick={submitted}>Login</button> */}
    //     {/* </form> */}
    // </div>
    <form className={styles.sysAdmin_cont} autoComplete="off">
      <div className={styles.inp_maincont}>
        <div
          // style={formData.isSubmited && formData.userName === "" && status === "fail" ? { borderBottom: "0.1px solid red" } : {}}
          className={`w-full h-auto  gap-4 relative text-xl font-semibold flex justify-start items-center text-[var(--text-primary-color)] focus-within:text-[var(--text-primary-color)] border-b-[1px] focus-within:border-b-[2px] border-[var(--text-secondary-color)] focus-within:border-[var(--text-secondary-color)]`}
        >
          <div
            style={{
              width: "fit-content",
              whiteSpace: "nowrap",
              fontSize: "18px",
            }}
          >
            User ID
          </div>
          {/* <input type="text" className={`${localStorage.theme === "dark" ? styles.dark : styles.inputfield}`} onChange={(e) => inputHandler(e, "name")} value={formData.userName} name="userName" autoComplete="off" /> */}
          <input
            type="text"
            className={`w-full border-none outline-none bg-[var(--secondary-color)] focus:border-none focus:outline-none`}
            onChange={(e) => inputHandler(e, "name")}
            value={formData.userName}
            name="userName"
            autoComplete="off"
          />
        </div>
        <div className={styles.suggestion}>
          {formData.isSubmited ? errorMesg : ""}
        </div>
      </div>
      <div className={styles.inp_maincont}>
        <div
          // style={formData.isSubmited && formData.password === "" && status === "fail" ? { borderBottom: "0.1px solid red" } : {}}
          className={`w-full h-auto  gap-4 relative text-xl font-semibold flex justify-start items-center text-[var(--text-primary-color)] focus-within:text-[var(--text-primary-color)]  border-b-[1px] focus-within:border-b-[2px] border-[var(--text-secondary-color)] focus-within:border-[var(--text-secondary-color)]`}
        >
          <div style={{ fontSize: "18px" }}>Password</div>
          <input
            type={showPassword ? "text" : "password"}
            className={`w-full border-none outline-none bg-[var(--secondary-color)] focus:border-none focus:outline-none`}
            onChange={(e) => inputHandler(e, "password")}
            value={formData.password}
            name="password"
            autoComplete="new-password"
          />
          {/*   <input type={showPassword ? "text" : "password"} className={`${styles.pswInp} ${styles.inputfield} outline-none bg-[var(--bg-primary)]`} onChange={(e) => inputHandler(e, "password")} value={formData.password} name="password" autoComplete="new-password" /> */}
          <div
            className="absolute right-[0.2rem] text-[var(--text-secondary-color)]"
            onClick={handleShowpassword}
          >
            {showPassword ? <GoEyeClosed /> : <GoEye />}
          </div>
        </div>
        <div className={styles.suggestion}>
          {formData.isSubmited ? pswErrMesg : ""}
        </div>
      </div>
      {/* fdf */}
      {/* <div className={styles.otp_cont}>
                <div className={styles.otp_inp_cont}>
                    <div className={styles.otp}>{randomNumber}</div>
                    <div className={styles.input}>
                        <input type="text" name="" id="" className={styles.otp_input} placeholder='Type the Code'/>
                    </div>
                </div>
                <div className={styles.regenerate_link} onClick={generateRandomNumber}>
                    Re-generate
                </div>
            </div> */}
      <div className="w-full h-auto gap-1.25 text-xl flex justify-end items-center">
        <div
          disabled={!(formData.password && formData.userName)}
          className={`${
            formData.password && formData.userName
              ? "px-6 py-2 text-[var(--text-secondary-color)] cursor-pointer"
              : "px-6 py-2 text-headingText opacity-55 cursor-not-allowed"
          }`}
          onClick={onReset}
        >
          Reset
        </div>

        <button
          disabled={!(formData.password && formData.userName)}
          className={`${
            formData.password && formData.userName
              ? "px-6 py-2 text-[var(--secondary-color)] bg-[#00b0f0] cursor-pointer"
              : "px-6 py-2 text-headingText opacity-55 cursor-not-allowed"
          }`}
          onClick={submitted}
          type="submit"
        >
          Next
        </button>
      </div>
      <div>
        <p>
          Please visit{" "}
          <span>
            <a href="https://www.medtrain.ai">www.medtrain.ai</a>
          </span>{" "}
          for more information about our products and offerings.
        </p>
      </div>
    </form>
  );
}

export default SysAdminLogin;
