import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { getAssessmentData } from "../../api/quesAns.rest";
import "./notification-panel.scss";

function NotificationPanel(props) {
  const views = {
    DIFFICULTY: "difficulty",
    QUESTION: "question",
    RESULT: "result"
  };

  const difficultyLevels = {
    NOVICE: "novice",
    COMPETENT: "competent",
    EXPERT: "expert",
  }

  const { show, onClose } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responseList, setResponseList] = useState({});
  const [view, setView] = useState(views.DIFFICULTY);
  const [score, setScore] = useState(0);
  const [difficulty, setDifficulty] = useState("");
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([]);

  const handleOptionChange = (qNo, val) => {
    const respObj = { ...responseList };
    respObj[qNo] = val;
    setResponseList(respObj)
  }

  const handleSubmitTest = () => {
    setView(views.RESULT);
    setScore(((Object.entries(responseList)?.map(([key, val]) => questionnaire?.[key]?.answer === val).filter((e) => e)?.length / questionnaire?.length) * 100).toFixed(0))
  }

  const handleChangeDifficulty = () => {
    setView(views.DIFFICULTY);
    setCurrentQuestion(0)
    setResponseList({});
    setDifficulty("");
    setScore(0);
    setQuestionnaire([])
  }

  const handleSubmitDifficulty = () => {
    console.log("Selected Difficulty: ", difficulty);
    setIsLoadingAssessment(true);
    getAssessmentData(difficulty).then((resp) => {
      setQuestionnaire(resp?.data?.data?.questions);
      setView(views?.QUESTION);
      setCurrentQuestion(0)
      setResponseList({});
      setScore(0)
    }).catch((e) => {
      console.error(e)
    }).finally(() => setIsLoadingAssessment(false));
  }

  useEffect(() => {

    return () => {
      handleChangeDifficulty();
    }
  }, [show])


  return (
    <div className={`notification-panel ${show ? "visible" : ""}`}>
      <header>
        <h3>Assessment</h3>
        <button className="close" onClick={onClose}>
          <AiOutlineClose />
        </button>
      </header>
      {view === views.DIFFICULTY ?
        <>
          <div className="difficulty-container p-3">
            <h6 className="mb-3">Select Difficulty</h6>
            <div className="difficulty-list d-flex flex-row justify-content-between align-items-center">
              {Object.entries(difficultyLevels)?.map(([key, val]) =>
                <div style={{ width: "30%" }} className="difficulty d-flex flex-column align-items-center">
                  <input
                    disabled={isLoadingAssessment}
                    className="form-check-input mb-2 flex-shrink-0"
                    type="radio"
                    style={{ height: "30px", width: "30px", color: "green" }}
                    value={val}
                    checked={difficulty === val}
                    onChange={() => setDifficulty(val)}
                  />
                  <label>{val?.charAt(0)?.toUpperCase() + val.slice(1).replace("-", " ")}</label>
                </div>
              )}
            </div>
            {isLoadingAssessment ?
              <div className="d-flex flex-row align-items-center justify-content-center py-5">
                <CircularProgress />
              </div>
              :
              <div className="btn-container mt-4 text-end">
                <button disabled={difficulty === ""} onClick={handleSubmitDifficulty} className="btn-submit fs-14 w-auto">Assess Me</button>
              </div>
            }
          </div>
        </>
        : view === views.QUESTION ?
          <>
            <div className="test-content-container p-3">
              <div className="question-container">
                {currentQuestion + 1}. {questionnaire?.at(currentQuestion)?.question}
              </div>
              <div className="options-container mt-3">
                {questionnaire?.at(currentQuestion)?.options?.map((opt, index) =>
                  <div key={`option-${index}-${currentQuestion}`} className="option d-flex flex-row mb-2">
                    <input
                      className="form-check-input me-2 flex-shrink-0"
                      type="radio"
                      name="options"
                      id={`option-${index}-${currentQuestion}`}
                      value={opt}
                      onChange={(e) => handleOptionChange(currentQuestion, e?.target?.value)}
                    />
                    <label style={{ fontSize: "0.9rem" }} className="form-check-label" htmlFor={`option-${index}-${currentQuestion}`}>
                      {opt}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="btn-container mt-3 px-3 text-end">
              {(currentQuestion + 1) === questionnaire?.length ?
                <button disabled={Object.keys(responseList)?.length !== questionnaire?.length} onClick={() => handleSubmitTest()} className="btn-submit fs-14 w-auto">Submit</button>
                :
                <button disabled={!responseList?.[currentQuestion]} onClick={() => setCurrentQuestion(currentQuestion + 1)} className="btn-submit fs-14 w-auto">Next</button>
              }
            </div>
          </>
          : <>
            <div style={{ height: "90%" }} className="result-content-container p-3 overflow-auto">
              <div className="score-container d-flex flex-column align-items-center mb-3">
                <span className="mb-3">Your Score</span>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={score} size="5rem" color={score >= 80 ? "success" : score < 80 && score >= 40 ? "primary" : "error"} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      color="text.secondary"
                    >{`${score}%`}</Typography>
                  </Box>
                </Box>
              </div>
              <div className="explanation-container">
                {questionnaire?.map((item, index) => <div className="question-item d-flex flex-column mb-3">
                  <span className="question">
                    {index + 1}. {item?.question}
                  </span>
                  {responseList?.[index] === item?.answer ?
                    <span style={{ fontSize: "0.9rem" }} className="correct-resp"><b>{item?.answer}</b> <AiOutlineCheck strokeWidth={"2rem"} fontSize="1.2rem" color="green" /></span>
                    :
                    <>
                      <span style={{ fontSize: "0.9rem" }} className="user-resp"><b>Your Response: </b>{responseList?.[index]} <AiOutlineClose strokeWidth={"2rem"} fontSize="1.2rem" color="red" /></span>
                      <span style={{ fontSize: "0.9rem" }} className="correct-resp"><b>Correct Answer: </b>{item?.answer}</span>
                    </>
                  }
                </div>)}
              </div>
              {isLoadingAssessment ?
                <div className="d-flex flex-row align-items-center justify-content-center py-3">
                  <CircularProgress />
                </div> :
                <div className="btn-container text-end mt-3">
                  <button onClick={handleSubmitDifficulty} className="btn-submit fs-14 w-auto mx-2">Re assess yourself</button>
                  <button onClick={handleChangeDifficulty} className="btn-submit fs-14 w-auto mx-2">Change Difficulty</button>
                </div>
              }
            </div>
          </>
      }
    </div>
  );
}

export default NotificationPanel;
