import { io } from "socket.io-client";

// const socket = io(process.env?.REACT_APP_SOCKET_URL, {
//   withCredentials: true,
//   transports: ["websocket"],
//   auth: {
//     token: localStorage.getItem("token"),
//   },
// });

export const socket = io(process.env?.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
  auth: {
    token: localStorage.getItem("token"),
  },
});