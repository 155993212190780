import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V12"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="var(--svgIconColor)"
        stroke="var(--svgIconColor)"
      />
      <path
        d="M12 16.0195V16"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="var(--svgIconColor)"
        stroke="var(--svgIconColor)"
      />
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="var(--svgIconColor)"
      />
    </svg>
  );
};

export default ErrorIcon;
