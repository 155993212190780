import React from "react";

const RightArrow = () => {
  return (
    <svg
      fill="var(--text-primary-color)"
      stroke="var(--text-primary-color)"
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
    >
      <path d="m636.5-339-12-13.5L782-510 624.5-667.5l12-13.5 171 171-171 171ZM201-267v-88.5q0-68 47.5-115.75t116-47.75h270l-149-149 12-13 171 171-171 171-12-13.5 149-149h-270q-60 0-103 43t-43 103v88.5H201Z" />
    </svg>
  );
};

export default RightArrow;
