import React from 'react';

const ResizeScreen = () => {
  return (
<svg width="30" height="30" viewBox="0 0 40 40" fill="var(--svgIconColor)" xmlns="http://www.w3.org/2000/svg">
<path d="M29 2V11H38M11 2V11H2M11 38V29H2M29 38V29H37.9" stroke="var(--svgIconColor)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
}

export default ResizeScreen;
