import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    showNotificationPanel: false
  },
  reducers: {
    toggleNotificationPanel: state => {
      state.showNotificationPanel  = !state.showNotificationPanel;
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleNotificationPanel } = globalSlice.actions

export default globalSlice.reducer