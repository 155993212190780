import api from '../config/axios';

const createStudent = (data) => {
  return api.post(`/device/manikin-type`, data);
}

const getAssessmentList=()=>{
    return api.get(`get-assessment-list`,{
        headers:{
            'Authorization':localStorage.getItem("token")
        }
    })
}

const createAssessment = (data) => {
    return api.post(`/create_assessment`, data);
  }

const getAssignedTest = (status) => {
    return api.get(`get-user-assessment-filter?status=${status}`,);
  }

  const getAllGeneralTestSessions = () => {
    return api.get(`/get-session-list`);
  }

export {
    createStudent,
    getAssessmentList,
    createAssessment,
    getAssignedTest,
    getAllGeneralTestSessions
  }