import React from 'react'
import { useNavigate } from 'react-router-dom';

function TopNavbar() {
    const navigate = useNavigate();
    const logOut = () => {
        if (window.confirm("Confirm Admin Log Out")) {
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            navigate("/login");
            window.location.reload();

        }
    }
    return (
        <div className=''>
            <div></div>
            <div className=''>
                <div className=" text-black" onClick={logOut}>
                    AD
                </div>
            </div>
        </div>
    )
}

export default TopNavbar;