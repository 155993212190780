import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { motion } from "framer-motion";

const style = {
  position: "absolute",
  top: 0,
  right: 60,
  bgcolor: "var(--bg-color-modal)",
  boxShadow: 24,
  height: "100%",
  minWidth: "20%",
  outline: "none",
};

export default function AssignTestModal({
  handleClose,
  open,
  children,
  heading,
}) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={style}
          component={motion.div}
          initial={{ x: "10vw", opacity: 0.8 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100vw", opacity: 0 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 30,
          }}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
}
