import React from "react";

const AssessmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
      fill="var(--svgIconColor)"
      stroke="var(--svgIconColor)"
    >
      <path d="m480-271.5-208-102v-176L163.5-600 480-752l317 152.143V-377h-17.5v-216L688-549.303V-373.5l-208 102Zm0-194L748-600 480-734 212.5-600 480-465.5Zm0 174.5 190.5-94.832V-541L480-447.593 289.5-541v155.168L480-291Zm.5-174.5ZM480-433Zm0 0Z" />
    </svg>
  );
};

export default AssessmentIcon;
