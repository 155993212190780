import axios from 'axios';
import { BASE_URL } from '../config/axios';

export const getAssessmentData = (difficulty) => {
    const payload = {
        questionType: difficulty
    }
    const config = {
        url: `${BASE_URL}/learn/questions-answer`,
        timeout: 0, // 0 meaning no timeout
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        },
        data: payload,
    }
    return axios(config);
}