import React, { useState, useEffect } from "react";
import RightArrow from "../../components/icons/RightArrow";
// import logo from "../../assets/images/login/medilogo.png"
import logo from "../../assets/images/login/logo_new.png";
import AddIcon from "../../components/icons/AddIcon";
import CustomModal from "../../components/common/CustomModal";
import { getAssignedTest } from "../../api/instructor.rest";
import AssignTestModal from "../../components/common/AssignTestModal";
import TooltipUi from "../../components/common/TooltipUi";
import { getAllGeneralTestSessions } from "../../api/instructor.rest";
import { useNavigate } from "react-router-dom";

const TestSessionComp = () => {
  const [modalHide, setModalHide] = useState(false);
  const [assignedTestData, setAssignedTestData] = useState([]);
  const [testData, setTestData] = useState({});
  const [testInd, setTestInd] = useState(null);
  const navigate = useNavigate();

  const getAllGeneralTestSessionsData = async () => {
    try {
      const res = await getAllGeneralTestSessions();
      setAssignedTestData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllGeneralTestSessionsData();
  }, []);

  const onClose = () => {
    setModalHide(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleModalClick = (data, tIndex) => {
    setTestInd(tIndex);
    setTestData(data);
    setModalHide(true);
  };

  const contentToHtml = (text) => {
    return text
      ?.split("\n\n")
      .map((paragraph) => {
        // Check if the paragraph starts with a number followed by a dot and a space
        if (/^\d+\.\s/.test(paragraph)) {
          // Extract the number, heading, and description
          const matches = paragraph.match(/^(\d+)\.\s(.*?):\s(.*)$/);

          if (matches) {
            // Extract the parts
            const [, number, heading, description] = matches;

            // Wrap the heading in <strong> tags
            const boldHeading = `<strong>${number}. ${heading}:</strong>`;

            // Return the formatted HTML
            return `<p>${boldHeading} ${description}</p>`;
          }
        } else {
          // For regular paragraphs, just wrap in <p> tags
          return `<p>${paragraph}</p>`;
        }
      })
      .join("");
  };

  const handleNavigateClick = (data) => {
    navigate(`/playAndLearn?mId=${data?.mnk_id}&sId=${data?.session_id}`);
  };

  return (
    <div className="w-[100vw-60px] h-[100vh]  overflow-y-scroll bg-background">
      <div className="z-40 w-max h-max flex p-2 gap-1">
        <a href="http://medtrain.ai" target="_medtrainai">
          <div className="manikin-cont w-[100px] h-[20px]">
            <img src={logo} alt="logo" className="" />
          </div>
        </a>
        {/* <div className="w-max text-sm font-normal text-[#787878]">
                    MedTrain AI
                </div> */}
      </div>

      <div className="px-4 py-4 bg-background">
        <div className="w-100 flex justify-end text-textColor text-[1.3rem] font-semibold">
          Sessions
        </div>
        <div className=" h-[100%] grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 pt-4  place-items-center overflow-y-scroll">
          {assignedTestData?.map((test, i) => {
            return (
              <div className="flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[17rem] p-2 bg-background text-textColor overflow-y-scroll">
                <div className="w-[100%] h-max flex flex-col gap-2">
                  <div className="pb-2">
                    <div
                      className={`font-extrabold text-base uppercase cursor-pointer ${
                        test?.assessmentMapper.length > 0
                          ? "text-sky-400"
                          : "text-textColor"
                      }`}
                      onClick={() => handleNavigateClick(test)}
                    >
                      {test?.session_name || test?.session_id}{" "}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-xs">Debriefing</div>
                    <div className="font-extrabold text-ellipsis line-clamp-3 text-xs">
                      {test?.debriefing.replace(/<[^>]*>/g, "")}
                    </div>
                  </div>
                  {/* <div>
                                <div className='font-normal text-xs'>Manikin name:</div>
                                <div className='font-normal '>{test?.masterDevice?.device_name}</div>
                            </div> */}
                  <div className="flex flex-col gap-2">
                    <div className=" font-semibold text-xs">Test On:</div>
                    <div className="font-extrabold">
                      {test?.assessmentMapper?.length > 0 &&
                        formatDate(test?.assessmentMapper[0]?.test_start)}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-xs">Test Score:</div>
                    <div className="font-extrabold ">
                      {Number(test?.adjusted_score).toFixed(1)}
                    </div>
                  </div>
                  <div>
                    {/* <div>Last Test Score</div>
                                <div>{stdDetails['Last Test Score']}</div> */}
                  </div>
                </div>

                <div className="w-[100%] h-max flex justify-end items-center gap-4">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleModalClick(assignedTestData[i], i)}
                  >
                    <TooltipUi
                      name="Details"
                      icons={<RightArrow />}
                      width="max"
                      placement="bottom"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <AssignTestModal
          heading={"Assign a test"}
          handleClose={onClose}
          open={modalHide}
        >
          <div className="w-[500px] h-[100vh] flex flex-col gap-2 overflow-y-scroll px-4 py-4">
            <div className="pb-2">
              <div className="font-bold text-2xl text-textColor uppercase">
                Session {testInd + 1}
              </div>
            </div>
            <div>
              <div className="font-semibold  text-textColor text-base">
                Debriefing
              </div>
              <div className="font-normal  text-textColor text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: contentToHtml(testData?.debriefing),
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div className="font-semibold  text-textColor text-base">
                Test On:
              </div>
              <div className="font-normal text-sm">
                {testData?.assessmentMapper?.length > 0 &&
                  formatDate(testData?.assessmentMapper[0]?.test_start)}
              </div>
            </div>
            <div>
              <div className="font-semibold  text-textColor text-base">
                Test Score
              </div>
              <div className="font-normal text-sm text-textColor">
                {Number(testData?.adjusted_score).toFixed(1)}
              </div>
            </div>
          </div>
        </AssignTestModal>
      </div>
    </div>
  );
};

export default TestSessionComp;
