import React from "react";

const AssignmentAdd = () => {
  return (
    <svg
      fill="var(--text-primary-color)"
      stroke="var(--text-primary-color)"
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
    >
      <path d="M250-200.5q-20.669 0-35.084-14.416Q200.5-229.331 200.5-250v-460q0-20.669 14.416-35.084Q229.331-759.5 250-759.5h198q-17.5-23.5-6-47.5t38-24q27.5 0 39 24t-7 47.5h198q20.669 0 35.084 14.416Q759.5-730.669 759.5-710v218q-4.973 1.854-9.286 3.387Q745.9-487.08 742-488v-222q0-12-10-22t-22-10H250q-12 0-22 10t-10 22v460q0 12 10 22t22 10h223.963q-.088 4.516-1.673 8.301T470-200.5H250ZM218-244v26-524 254-2 246Zm98.5-85h168.027q2.973-4 5.163-8.216 2.191-4.216 4.81-9.284h-178v17.5Zm0-142.5H608q8.5 2.5 10 2.5t3.5-4v-16h-305v17.5Zm0-142.5h327v-17.5h-327v17.5ZM480-755q10.4 0 17.2-6.8 6.8-6.8 6.8-17.2 0-10.4-6.8-17.2-6.8-6.8-17.2-6.8-10.4 0-17.2 6.8-6.8 6.8-6.8 17.2 0 10.4 6.8 17.2 6.8 6.8 17.2 6.8Zm238.307 653.5q-57.307 0-96.807-39.693-39.5-39.692-39.5-97Q582-295.5 621.693-335q39.692-39.5 97-39.5 57.307 0 96.807 39.693 39.5 39.692 39.5 97Q855-180.5 815.307-141q-39.692 39.5-97 39.5ZM701-149h35.5v-71.5H808v-35h-71.5V-327H701v71.5h-71.5v35H701v71.5Z" />
    </svg>
  );
};

export default AssignmentAdd;
