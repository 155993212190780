import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const TooltipUi = ({ name, icons, width, placement }) => {
  return (
    <Box sx={{ width: width }} padding={0} justifyContent="center">
      <Tooltip title={name} placement={placement} arrow>
        <Button>{icons}</Button>
      </Tooltip>
    </Box>
  );
};

export default TooltipUi;
