import React from "react";

const HelpCenter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
      fill="var(--svgIconColor)"
      stroke="var(--svgIconColor)"
    >
      <path d="M481.243-302.807q10.728 0 17.858-7.213 7.13-7.212 7.13-18.062 0-10.85-7.13-17.48-7.13-6.63-17.563-6.63-10.432 0-18.062 6.58-7.63 6.58-7.63 17.337 0 11.043 7.334 18.255 7.335 7.213 18.063 7.213Zm-16.705-112.385h28.154q1.539-23.923 7.923-36.635 6.385-12.711 30.924-35.135 12.769-11.769 26.423-31.579 13.654-19.809 13.654-48.882 0-45.846-32.52-70.769-32.519-24.924-66.442-24.924-35.462 0-58.231 22.827t-33.5 39.92l27.923 13.177q8.077-11.962 19.731-30.058 11.654-18.096 46.825-18.096 43.136 0 56.906 23.077 13.769 23.077 13.769 44.654 0 25.192-15.25 39.019-15.25 13.827-23.135 23.75-32.307 33.115-37.731 47.077-5.423 13.961-5.423 42.577ZM242.895-189.538q-22.456 0-37.906-15.451-15.451-15.45-15.451-37.906v-474.21q0-22.456 15.451-37.906 15.45-15.451 37.906-15.451h474.21q22.456 0 37.906 15.451 15.451 15.45 15.451 37.906v474.21q0 22.456-15.451 37.906-15.45 15.451-37.906 15.451h-474.21Zm.182-28.924h473.846q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-473.846q0-9.231-7.692-16.923-7.692-7.692-16.923-7.692H243.077q-9.231 0-16.923 7.692-7.692 7.692-7.692 16.923v473.846q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Zm-24.615-523.076v523.076-523.076Z" />
    </svg>
  );
};

export default HelpCenter;
