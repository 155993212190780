import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function WatchLearn() {
    return (
        <div className="row">
            <div className='col-md-8'>
                <div className="box-style">
                    <div className="row">
                        <div className="col-5">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="0"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#979797" } }}
                                        value="0"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                        }} />}
                                        label="Simulation with Mannequin"
                                    />
                                    <FormControlLabel
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#979797" } }}
                                        value="1"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                        }} />}
                                        label="Instructor with Mannequin"
                                    />
                                    <FormControlLabel
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#979797" } }}
                                        value="2"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                        }} />}
                                        label="Instructor on Human"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-sm-4">
                            <FormControl>
                                <FormLabel className='fs-14'>Procedure:</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="0"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#979797" } }}
                                        value="0"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                        }} />}
                                        label="Correct"
                                    />
                                    <FormControlLabel
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#979797" } }}
                                        value="1"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            },
                                        }} />}
                                        label="Incorrect"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-sm-3">
                            <div className="d-flex justify-content-start h-100">
                                <button className="btn-submit">Watch</button>
                            </div>
                        </div>
                    </div>
                    <div className="border-dotted-bottom"></div>
                    <div>
                        <img src="/assets/images/maniqueen.png" alt="" className="img-fluid w-100" />
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-style">
                    <h4 className='bg-header'>Library of Visual Aids</h4>
                    <div className="d-flex gap-2 flex-wrap">
                        <div className='side-img-container'>
                            <img src="https://www.mccmdclinic.org/wp-content/uploads/2020/03/medical-tools-for-surgery.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Tracheal Tube</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://www.msdmanuals.com/-/media/manual/home/images/d/e/n/den_view_mouth.gif?thn=0&sc_lang=en" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Mouth Structure</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://en.pimg.jp/066/287/261/1/66287261.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Front View</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://en.pimg.jp/098/468/494/1/98468494.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Side View</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://media.gettyimages.com/id/566420775/photo/anatomical-view-of-human-torso.jpg?s=1024x1024&w=gi&k=20&c=VthCwZNCSftPyEpdHGB-Ocn31hGlyNFuisXy3tMrbzM=" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Tracheal Tube</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://www.mccmdclinic.org/wp-content/uploads/2020/03/medical-tools-for-surgery.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Tracheal Tube</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://www.msdmanuals.com/-/media/manual/home/images/d/e/n/den_view_mouth.gif?thn=0&sc_lang=en" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Mouth Structure</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://en.pimg.jp/066/287/261/1/66287261.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Front View</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://en.pimg.jp/098/468/494/1/98468494.jpg" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Side View</p>
                            </div>
                        </div>
                        <div className='side-img-container'>
                            <img src="https://media.gettyimages.com/id/566420775/photo/anatomical-view-of-human-torso.jpg?s=1024x1024&w=gi&k=20&c=VthCwZNCSftPyEpdHGB-Ocn31hGlyNFuisXy3tMrbzM=" alt="" className="side-img-list" />
                            <div className="side-img-title">
                                <p>Tracheal Tube</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WatchLearn;