import api from '../config/axios';

const createOrganization = (data) => {
  return api.post(`/org/organization/`, data);
}
const mapUserToOrg = (orgId, userId, data) => {
  return api.post(`/org/allocation/${orgId}/${userId}`, data);
}
const updateOrganization = (orgId, data) => {
  return api.put(`/org/organization/${orgId}`, data);
}
const getOrganizations = () => {
  return api.get(`/org/organization`);
}
const getOrganizationDetails = (orgId) => {
  return api.get(`/org/organization/${orgId}`);
}
const redirectURL = (params) => {
  return api.get(`/org/redirect`, {
    params // refers to query params
  });
}
const deleteOrganization = (orgId) => {
  return api.delete(`/org/organization/${orgId}`);
}
const removeUserFromOrg = (orgId, userId) => {
  return api.delete(`/org/allocation/${orgId}?userId=${userId}`);
}

export {
  createOrganization,
  mapUserToOrg,
  updateOrganization,
  getOrganizations,
  getOrganizationDetails,
  redirectURL,
  deleteOrganization,
  removeUserFromOrg
}