import api from '../config/axios';

const commandMQTT = (action, deviceId) => {
  return api.get(`/mqtt/Instructions/manikin/${deviceId}/${action}`);
}

const commandMQTTWithAssignment = (action, deviceId, assessmentId) => {
  return api.get(`/mqtt/Instructions/manikin/${deviceId}/${action}?assessmentId=${assessmentId}`);
}

const getSessionsByManikinId = (manikinId) => {
  return api.get(`/mqtt/device-logs-group-by-session/${manikinId}`);
}

const getLogsBySessionId = (sessionId) => {
  return api.get(`/mqtt/device-logs-by-session?sessionId=${sessionId}`);
}

const updateSensorThreshold = (data)=>{
  return api.post('update_sensor_threshold',data)
}

const UpdateSessionName = (data)=>{
  return api.put('mqtt/update-session',data)
}

const userSessionAll = (pageNumber,pageSize,data)=>{
  return api.post(`/mqtt/user-logs-group-by-session?page=${pageNumber}&pageSize=${pageSize}`,data)
}

const getSensorThresholdByDeviceId=(id)=>{
  return api.get(`get-sensor-threshold/${id}`)
}


export {
  commandMQTT,
  commandMQTTWithAssignment,
  getSessionsByManikinId,
  getLogsBySessionId,
  updateSensorThreshold,
  UpdateSessionName,
  userSessionAll,
  getSensorThresholdByDeviceId
}