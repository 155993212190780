import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    isVisible: false,
    message: "",
    severity: "",
  },
  reducers: {
    showAlert: (state, action) => {
      state.isVisible = true;
      state.message = action?.payload?.message || "";
      state.severity = action?.payload?.severity || "";
    },
    hideAlert: state => {
      state.isVisible = false;
      state.message = "";
      state.severity = "";
    }
  }
})

// Action creators are generated for each case reducer function
export const { showAlert, hideAlert } = alertSlice.actions

export default alertSlice.reducer