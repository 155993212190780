import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginstate: false
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    showStatelogin: (state, action) => {
        state.loginstate = !action.loginstate
      },
  },
})

export const {showStatelogin} = counterSlice.actions

export default counterSlice.reducer