import { toast } from "react-toastify";
import { UpdateSessionName } from "../../api/mqtt.rest";

export const UpdateSessionFn = async (
  id,
  ind,
  sessionName,
  sessionDataList,
  setSessionDataList,
  setSelectedSessionName
) => {
  const data = {
    session_id: id,
    session_name: sessionName,
  };
  sessionDataList[ind].session_name = sessionName;
  setSessionDataList(sessionDataList);
  try {
    const res = await UpdateSessionName(data);
    setSelectedSessionName(sessionName)
    if (res?.status === 200) {
      toast.success("session name updated successfully ");
    }
  } catch (error) {
    console.log("er", error);
  }
};
