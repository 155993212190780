import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../store/alert.slice";

export const toastSeverity = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
}

function Toastr(props) {
  const dispatch = useDispatch();
  const message = useSelector((state) => state?.alert?.message)
  const severity = useSelector((state) => state?.alert?.severity)
  const isVisible = useSelector((state) => state?.alert?.isVisible)
  const handleClose = () => {
    dispatch(hideAlert());
  };
  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toastr;
