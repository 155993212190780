import { IconButton } from '@mui/material';
import React from 'react';
import { FaPen, FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteManikin } from '../../api/device.rest';
import { hideAlert, showAlert } from '../../store/alert.slice';
import { toastSeverity } from '../toastr';

function ManikinTable({ tableData, refreshTableData, handleEdit }) {
    const dispatch = useDispatch();

    const handleDelete = async (deviceId) => {
        const confirmation = window.confirm("Are you sure you want to delete?");
        if (confirmation) {
            await deleteManikin(deviceId).then((resp) => {
                dispatch(showAlert({ message: resp?.data?.data || "Data Deleted Successfully", severity: toastSeverity.SUCCESS }));
                setTimeout(() => dispatch(hideAlert()), 5000)
                refreshTableData();
            }).catch((e) => {
                console.error(e);
                dispatch(showAlert({ message: e?.response?.data?.data || "Something went wrong", severity: toastSeverity.ERROR }))
                setTimeout(() => dispatch(hideAlert()), 5000)
            })
        }
    }
    return (
        <>
            <div className='table-style'>
                <table className="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>Device ID</th>
                            <th>Device Sub Type ID</th>
                            <th>Device Name</th>
                            <th>Device Status</th>
                            <th>Procured From</th>
                            <th>Procured on</th>
                            <th>Created Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!tableData && Array.isArray(tableData) && tableData?.length > 0 ?
                                tableData?.map((item) => {
                                    return <tr>
                                        <td><Link to={`${item?.device_id}`}>{item?.device_id}</Link></td>
                                        <td>{item?.subtype_id}</td>
                                        <td>{item?.device_name || "-"}</td>
                                        <td><span className={`badge ${item?.status === "Active" ? "bg-success" : item?.status === "Deactive" ? "bg-danger" : "bg-warning"}`}>{item?.status}</span></td>
                                        <td>{item?.procure_from}</td>
                                        <td>{new Date(item?.procure_on)?.toLocaleDateString() || "-"}</td>
                                        <td>{new Date(item?.rec_ins_date)?.toLocaleDateString() || "-"}</td>
                                        <td>
                                            <IconButton aria-label="delete" size="small" onClick={() => { handleDelete(item?.device_id) }} >
                                                <FaTrashAlt fontSize="small" className="text-danger cursor-pointer" />
                                            </IconButton>
                                            <IconButton aria-label="edit" size="small" onClick={() => { handleEdit(item) }} >
                                                <FaPen fontSize='small' />
                                            </IconButton>
                                        </td>
                                    </tr>
                                }) :
                                <tr>
                                    <td colSpan={7} className="text-center">No Data Available</td>
                                </tr>
                        }
                        {/* <tr>
                            <td>KH4501</td>
                            <td>45675321</td>
                            <td><span className="badge bg-warning">Repairing</span></td>
                            <td>Ajex</td>
                            <td>James</td>
                            <td>01.08.2023</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>PH4709</td>
                            <td>254876</td>
                            <td><span className="badge bg-success">Active</span></td>
                            <td>Hurry</td>
                            <td>William</td>
                            <td>10.09.2023</td>
                            <td>Edit</td>
                        </tr>
                        <tr>
                            <td>HJ4858</td>
                            <td>5685154</td>
                            <td><span className="badge bg-danger">Deactive</span></td>
                            <td>Jhon</td>
                            <td>Benjamin</td>
                            <td>19.10.2023</td>
                            <td>Edit</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ManikinTable;