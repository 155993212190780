import React from "react";

const DebriefingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      viewBox="0 -960 960 960"
      width="40"
    >
      <path
        d="M364.5-270H596v-17.5H364.5v17.5Zm0-143H596v-17.5H364.5v17.5Zm-66.981 260q-20.957 0-34.988-14.181Q248.5-181.363 248.5-202.28v-555.44q0-20.918 14.181-35.099Q276.862-807 298-807h304l109.5 109v495.5q0 21.137-14.193 35.319Q683.114-153 662.157-153H297.519ZM581.5-677v-112.5H298q-12 0-22 10t-10 22v555q0 12 10 22t22 10h364q12 0 22-10t10-22V-677H581.5ZM266-789.5V-656v-133.5 619-619Z"
        fill="var(--svgIconColor)"
      />
    </svg>
  );
};

export default DebriefingIcon;
