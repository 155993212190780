export  function getCurrentDateTime(d) {
    if (d.slice(-1) != 'Z') {
        d = `${d}Z`
    }
    const now = new Date(d);
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const date = String(now.getDate()).padStart(2, '0');
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds} | ${month}/${date}/${year}`;
  }