import React from "react";

const ExpandScreen = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 84 51"
      fill="var(--svgIconColor)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.3333 14.5714V5.88686C79.3333 5.32586 79.0347 4.811 78.4373 4.34229C77.84 3.87357 77.182 3.64043 76.4633 3.64286H65.3333V0H76.4633C78.61 0 80.402 0.562214 81.8393 1.68664C83.2767 2.81107 83.9969 4.21114 84 5.88686V14.5714H79.3333ZM1.00575e-05 14.5714V5.88686C1.00575e-05 4.20871 0.720232 2.80864 2.16068 1.68664C3.60112 0.564642 5.39312 0.00242857 7.53667 0H18.6667V3.64286H7.54134C6.82268 3.64286 6.16312 3.876 5.56268 4.34229C4.96223 4.80857 4.66356 5.32343 4.66668 5.88686V14.5714H1.00575e-05ZM65.3333 51V47.3571H76.4633C77.1789 47.3571 77.8369 47.124 78.4373 46.6577C79.0378 46.1914 79.3364 45.6766 79.3333 45.1131V36.4286H84V45.1168C84 46.7925 83.2813 48.1914 81.844 49.3134C80.4067 50.4354 78.6131 50.9976 76.4633 51H65.3333ZM7.53667 51C5.39001 51 3.59645 50.439 2.15601 49.317C0.715565 48.195 -0.00310105 46.7937 1.00575e-05 45.1131V36.4286H4.66668V45.1168C4.66668 45.6754 4.96534 46.189 5.56268 46.6577C6.16001 47.1264 6.81801 47.3596 7.53667 47.3571H18.6667V51H7.53667ZM17.948 36.9896V14.0104H66.052V36.9896H17.948Z"
        fill="var(--svgIconColor)"
      />
    </svg>
  );
};

export default ExpandScreen;
