import { SOCKET_DATA_HEADERS } from "../../constant";
import { getCurrentDateTime } from "../common/utils";

const SOCKET_DATA_TABLE = ({ body }) => {
  const TableHeader = ({ header }) => {
    return (
      <thead className="bg-white sticky top-0">
        <tr className="w-full rounded-tl-md rounded-tr-md text-blackishText sm:text-[0.7rem] 2xl:text-sm font-semibold tracking-wider h-max">
          {header?.map((head) => (
            <th
              className=" pl-5 pr-2 py-2 capitalize bg-[var(--bg-secondary)] text-textColor  border-2 border-[var(--primary-color)]"
              key={head?.name}
            >
              <div className="flex justify-center items-center">
                <p className="w-max text-tableHeaderTextColor font-semibold m-0">
                  {head?.name}
                </p>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const loadTableColumns = (
    rowData,
    rowIndex,
    header,
    classNames = "text-center py-2 border-r-2 border-[var(--primary-color)]",
    data,
    setData,
    arr,
    myBalance,
    isSubmit,
    myLevel
  ) => {
    return (
      <>
        {header?.map((col, index) => {
          switch (col.key) {
            case "RaspTime":
              return (
                <td className={classNames} key={`${col.key}-${index}`}>
                  <span className=" sm:text-[0.7rem] 2xl:text-xs">
                    {getCurrentDateTime(rowData[`${col.key}`])}{" "}
                  </span>
                </td>
              );
            default:
              return (
                <td className={classNames} key={`${col.key}-${index}`}>
                  <span className=" sm:text-[0.7rem] 2xl:text-xs">
                    {rowData[`${col.key}`]}{" "}
                  </span>
                </td>
              );
          }
        })}

        {/* TableBody for Columnb Chooser */}
        <td className={classNames}>
          <span className=""></span>
        </td>
      </>
    );
  };

  return (
    <div className="">
      <div className="w-full overflow-y-scroll h-[200px]">
        <table className="w-full text-sm mb-12 text-left text-textColor h-[200px] relative">
          <TableHeader header={SOCKET_DATA_HEADERS} />
          <tbody className=" overflow-y-scroll h-[250px]">
            {body?.map((rowData, index, arr) => {
              return (
                <tr
                  className="text-blackishText bg-[var(--bg-secondary)]  py-2 text-xs font-normal tracking-wider border-b-[0.5px] border-b-searchBtnColor-hover"
                  key={index}
                >
                  {loadTableColumns(rowData, index, SOCKET_DATA_HEADERS)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SOCKET_DATA_TABLE;
