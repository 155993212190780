import { useLocation, Navigate, Outlet } from "react-router-dom";
// import { useContext } from "react";
// import AuthContext from "../Context/authProvider";

import React from "react";

const Auth = ({ allowedRoles }) => {
//   const { auth } = useContext(AuthContext);
  const location = useLocation();
  const myrole = localStorage.getItem("userType")
  const allowded = allowedRoles.find((role) => role===myrole)
  

  return  allowded ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default Auth;