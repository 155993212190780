import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';


function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        instituteId: '',
        userName: '',
        password: '',
        isSubmited: false,
    })


    /*
    * Input 
    * Event Handller
    */
    const inputHandler = (eve, type) => {
        const txt = eve.target.value;
        if (type === 'id') {
            setFormData({
                ...formData,
                instituteId: txt
            });
        }
        if (type === 'name') {
            setFormData({
                ...formData,
                userName: txt
            });
        }
        if (type === 'password') {
            setFormData({
                ...formData,
                password: txt
            });
        }
    }

    /*
    * Input 
    * Event Handller
    */
    const valid = () => {
        let checked = true;
        const { instituteId, userName, password } = formData;
        if (instituteId !== "Med abc") {
            checked = false;
        }
        if (userName !== "Admin") {
            checked = false;
        }
        if (password !== "admin") {
            checked = false;
        }

        return checked;
    }

    /*
    * Form
    * Submitted
    */
    const submitted = () => {
        setFormData({
            ...formData,
            isSubmited: true
        });
        if(valid()){
            localStorage.setItem('token', "tokenvalueset");
            navigate("/watchLearn");
            window.location.reload();
        }
    }


    return (
        <div className='auth-form-container' style={{background:"red"}}>
            {/* <form> */}
            <input type="text"
             style={formData.isSubmited && formData.instituteId !== 'Med abc' ? { border: "1px solid red", borderRadius: "4px" } : {}}
                onChange={(e) => inputHandler(e, 'id')}
                name='instituteId'
                className="login-input"
                placeholder='Institution ID' />
            <input type="text"
             style={formData.isSubmited && formData.userName !== 'Admin' ? { border: "1px solid red", borderRadius: "4px" } : {}}
                onChange={(e) => inputHandler(e, 'name')}
                name='userName'
                className="login-input"
                placeholder='Username' />
            <input type="text"
             style={formData.isSubmited && formData.password !== 'admin' ? { border: "1px solid red", borderRadius: "4px" } : {}}
                onChange={(e) => inputHandler(e, 'password')}
                name='password'
                className="login-input mb-0"
                placeholder='Password' />
            <div className="text-end m-b-15"><Link to="#" className='fs-12 c-primary'>Forget Password</Link></div>
            <button className="btn-submit m-b-25" onClick={submitted}>Login ....</button>
            <p className='text-center fs-14'>Create new account? <Link to="#" className='text-decoration-underline c-primary'>Sign In</Link></p>
            {/* </form> */}
        </div>
    )
}

export default Login;