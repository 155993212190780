import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "./logtable.css";
import React, { useEffect, useState } from "react";
import { useDarkMode } from "../../hooks/useDarkMode";

function LogTable(props) {
  const { listingMessage } = props;
  const darkMode = useDarkMode(state => state.theme)

  const [colums, setColumns] = useState([
    { field: "date" },
    // { field: "key" },
    { field: "lapsedTime" },
    { field: "message" },
    { field: "type" },
  ]);
  return (
    <div
      className={`${darkMode==="dark" ? "ag-theme-balham-dark" : "ag-theme-balham"} p-2`} // applying the Data Grid theme
      style={{ height: 500, width: "100%" }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact rowData={listingMessage} columnDefs={colums} />
    </div>
  );
}

export default LogTable;
