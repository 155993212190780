import { configureStore } from '@reduxjs/toolkit'
import alertReducer from '../store/alert.slice'
import globalReducer from '../store/global.slice'

// create react root store
export default configureStore({
  reducer: {
    alert: alertReducer,
    global: globalReducer
  }
})