const pathStrings = {
  body: {
    z:
      "M312 692.5C124.5 722 18.5 457.5 180.5 364C188 355.5 202.333 353.167 209.5 352C223.9 347.2 258.833 303 274.5 281.5C283.7 279.5 290 306.333 292 320L328 306C344.4 354.4 365.167 360.833 373.5 358C389.1 340.8 388.333 305.167 386 289.5C411.6 276.7 442 274.833 454 275.5C472.8 274.7 477.5 344.5 477.5 379.5C473.1 397.5 485.333 398 492 396C532 380.8 586.667 382.333 609 385C634.2 373.4 662.167 344.5 673 331.5C770.5 220.5 1040.35 218.187 1196.5 243V700C1019.7 714.4 828.667 687.5 748 669.5C673.2 639.1 637.5 611.167 629 601C604.6 579.8 578.167 574.833 568 575C534.441 574.716 508.161 585.536 492 595.708C485.431 599.842 480.535 603.869 477.5 607L432.5 643.5C368.1 683.1 325.333 692.667 312 692.5Z",
    f:
      "M344.898 734.491C171.422 811.515 0.576057 583.462 132.856 451.219C137.901 441.068 151.142 435.104 157.763 432.123C170.43 423.759 192.733 372.024 202.301 347.202C210.67 342.889 223.7 367.177 229.169 379.86L260.319 357.02C288.687 399.526 310.411 400.365 317.727 395.472C328.344 374.82 318.381 340.6 312.072 326.071C333.487 307.081 362.368 297.41 374.132 294.948C392.084 289.309 414.69 355.515 423.748 389.322C424.157 407.848 436.103 405.164 442.025 401.507C492 380.5 586.667 382.333 609 385C634.2 373.4 662.167 344.5 673 331.5C770.5 220.5 1040.35 218.187 1196.5 243V700C1019.7 714.4 828.667 687.5 748 669.5C673.2 639.1 637.5 611.167 629 601C604.6 579.8 578.167 574.833 568 575C534.441 574.716 509.873 584.238 493.713 594.41C488.438 600.103 484.75 605.261 482.629 609.07L448.61 655.973C396.653 710.892 357.82 731.201 344.898 734.491Z",
    t:
      "M375.406 777.234C227.776 896.532 3.72752 720.468 97.2737 558.494C99.5189 547.383 110.765 538.196 116.388 533.602C126.459 522.245 134.612 466.5 137.43 440.047C144.397 433.715 163.27 453.804 171.835 464.639L196.012 434.515C234.415 468.231 255.616 463.419 261.417 456.798C266.327 434.103 247.846 403.627 237.992 391.226C253.762 367.34 279.156 350.524 289.882 345.101C305.763 335.008 344.733 393.107 362.233 423.418C367.423 441.206 378.267 435.523 383.041 430.457C458.5 381 586.667 382.333 609 385C634.2 373.4 662.167 344.5 673 331.5C770.5 220.5 1040.35 218.187 1196.5 243V700C1019.7 714.4 828.667 687.5 748 669.5C673.2 639.1 637.5 611.167 629 601C604.6 579.8 578.167 574.833 568 575C534.441 574.716 504.5 584.415 492 595.708C479.5 607 477.046 616.21 475.983 620.439L455.262 674.549C419.29 741.043 387.036 770.711 375.406 777.234Z"
  },
  funnel: {
    z:
      "M254 379C252.8 351.4 271.167 317.5 280.5 304C290.5 304 292.333 320 292 328V358C290.4 380.8 310.667 398.833 321 405C331.4 411.8 357.333 422.833 369 427.5C365.4 417.5 347.167 402.667 338.5 396.5C327.3 389.7 318.167 377.667 315 372.5C297 342.9 318.667 321.167 330.5 316C341.5 348 366.167 361.167 373.5 358C386.3 348.4 388.833 318 388.5 304C428.1 300.4 433.333 352.5 431 379C427 407 449.333 408 461 405C483.8 405 476.833 420 470.5 427.5C477.232 427.5 483.653 426.593 489.5 425.204C502.413 422.135 512.526 416.712 517 413.5C574.2 398.3 663.833 404.5 701.5 409.5C729.9 422.7 802.333 383.667 835 362.5C850.6 366.9 855.167 384.667 855.5 393C833.5 389.8 783 418 760.5 432.5C787.7 436.5 821.5 430.833 835 427.5L873 419L883 432.5C860.2 430.9 845.833 440.833 841.5 446C845.9 457.6 881 457.5 898 456L907.5 471.5C889.1 476.3 862.5 469.5 851.5 465.5C834.3 460.7 788.333 457.167 767.5 456C736.7 456 688 437.167 664 432.5C594.571 419 506.333 441.833 503.5 446C611.5 428.306 663.833 453.333 679 456C695.8 458.4 736 467.333 754 471.5C925.2 508.3 1115.5 503.167 1196.5 496V528.5C984.9 545.3 792.5 514 715.5 496C682.3 484.4 632.333 474.833 611.5 471.5C573.1 461.9 514.167 470.833 489.5 476.5C392.7 497.3 343.167 470.833 330.5 455C300.5 459.8 280 447 273.5 440C260 421.5 255.5 413.5 254 379Z",
    f:
      "M207.734 446.685C199.432 420.336 208.399 382.838 213.92 367.382C223.579 364.794 229.491 379.774 231.24 387.588L239.004 416.566C243.36 439.003 267.603 451.176 279.181 454.458C290.986 458.335 318.892 462.28 331.368 463.768C325.303 455.041 303.852 445.432 293.884 441.719C281.306 438.049 269.369 428.79 264.973 424.619C239.926 400.686 255.229 374.085 265.322 366.032C284.229 394.095 311.463 400.429 317.727 395.472C327.606 382.886 322.185 352.866 318.24 339.429C355.559 325.703 374.098 374.673 378.703 400.874C382.086 428.955 403.918 424.141 414.41 418.224C436.433 412.323 433.586 428.615 429.41 437.498C435.913 435.756 441.88 433.218 447.168 430.363C456.085 430.378 477.035 429.367 489.5 425.204C502.413 422.135 512.526 416.712 517 413.5C574.2 398.3 663.833 404.5 701.5 409.5C729.9 422.7 802.333 383.667 835 362.5C850.6 366.9 855.167 384.667 855.5 393C833.5 389.8 783 418 760.5 432.5C787.7 436.5 821.5 430.833 835 427.5L873 419L883 432.5C860.2 430.9 845.833 440.833 841.5 446C845.9 457.6 881 457.5 898 456L907.5 471.5C889.1 476.3 862.5 469.5 851.5 465.5C834.3 460.7 788.333 457.167 767.5 456C736.7 456 688 437.167 664 432.5C594.571 419 506.333 441.833 503.5 446C611.5 428.306 663.833 453.333 679 456C695.8 458.4 736 467.333 754 471.5C925.2 508.3 1115.5 503.167 1196.5 496V528.5C984.9 545.3 792.5 514 715.5 496C682.3 484.4 632.333 474.833 611.5 471.5C573.1 461.9 514.167 470.833 489.5 476.5C474.7 474.9 463.963 478.107 460.445 479.911C372.326 525.056 317.631 512.311 301.298 500.296C273.562 512.697 250.448 505.639 242.358 500.56C224.53 486.184 218.112 479.621 207.734 446.685Z",
    t:
      "M168.427 534.735C153.587 511.433 152.543 472.891 153.876 456.533C162.536 451.533 172.124 464.473 175.835 471.568L190.835 497.548C200.85 518.094 227.418 523.578 239.45 523.751C251.857 524.44 279.832 521.029 292.269 519.237C284.152 512.377 260.945 508.647 250.356 507.64C237.256 507.351 223.33 501.497 218.004 498.606C187.616 481.971 195.513 452.316 203.177 441.925C228.704 464.138 256.649 463.207 261.417 456.798C267.702 442.084 254.696 414.491 247.407 402.533C279.902 379.615 310.484 422.118 321.713 446.235C332.249 472.484 352.09 462.183 360.694 453.751C380.439 442.351 381.906 458.825 380.171 468.487C386.001 465.121 391.109 461.125 395.478 456.999C409.318 448.468 447.5 430.167 489.5 425.204C502.413 422.135 512.526 416.712 517 413.5C574.2 398.3 663.833 404.5 701.5 409.5C729.9 422.7 802.333 383.667 835 362.5C850.6 366.9 855.167 384.667 855.5 393C833.5 389.8 783 418 760.5 432.5C787.7 436.5 821.5 430.833 835 427.5L873 419L883 432.5C860.2 430.9 845.833 440.833 841.5 446C845.9 457.6 881 457.5 898 456L907.5 471.5C889.1 476.3 862.5 469.5 851.5 465.5C834.3 460.7 788.333 457.167 767.5 456C736.7 456 688 437.167 664 432.5C594.572 419 506.333 441.833 503.5 446C611.5 428.306 663.833 453.333 679 456C695.8 458.4 736 467.333 754 471.5C925.2 508.3 1115.5 503.167 1196.5 496V528.5C984.9 545.3 792.5 514 715.5 496C682.3 484.4 632.333 474.833 611.5 471.5C573.1 461.9 514.167 470.833 489.5 476.5C467.5 476.5 434.75 493.115 421.126 501.422C347.694 567.836 291.564 569.681 272.677 562.303C249.097 581.46 224.943 580.625 215.814 577.812C194.873 568.541 186.976 563.863 168.427 534.735Z"
  },
  tracheaPath: {
    z: "M369.191 361C380.833 392.217 398.799 420.782 425 431.5C469 449.5 489.5 434 528 425C566.5 416 646 404.5 750.5 437L778.5 445",
    f: "M320.5 399C332.142 430.217 349.245 461.2 376.309 469.5C435 487.5 489.5 434 528 425C566.5 416 646 404.5 750.5 437L778.5 445"
  },
  esoPath: {
    z: "M369.186 361C386.167 406.728 422.698 464.006 524.5 457.5L540.5 453.5",
    f: "M320 399C339 444 357 488 456.5 471.5L540.5 453.5"
  }
};

const pathDef = {
  body: { label: "body", selector: "path#manikinBody" },
  funnel: { label: "funnel", selector: "path#funnel" },
  tracheaPath: { label: "tracheaPath", selector: "path#trachea" },
  esoPath: { label: "esoPath", selector: "path#esophagus" },
};


export {pathStrings, pathDef}