import React from "react";

const AssignmentInd = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
    >
      <path
        d="M249.764-200.5q-20.901 0-35.083-14.181-14.181-14.182-14.181-35.083v-460.472q0-20.902 14.181-35.083Q228.863-759.5 250-759.5h198q-17-25.5-4.824-48.5 12.177-23 36.75-23 24.574 0 36.824 23Q529-785 512-759.5h198q21.138 0 35.319 14.181 14.181 14.181 14.181 35.083v460.472q0 20.901-14.181 35.083-14.181 14.181-35.083 14.181H249.764ZM480-755.5q10.4 0 17.2-6.8 6.8-6.8 6.8-17.2 0-9.9-6.8-16.95-6.8-7.05-17.2-7.05-10.4 0-17.2 7.05-6.8 7.05-6.8 16.95 0 10.4 6.8 17.2 6.8 6.8 17.2 6.8ZM218-250q55.687-45.535 123.272-67.768Q408.857-340 479.928-340q71.072 0 138.709 21.732Q686.274-296.535 742-250v-460q0-12-10-22t-22-10H250q-12 0-22 10t-10 22v460Zm262.354-151.5q48.646 0 82.146-33.854 33.5-33.853 33.5-82.5Q596-566.5 562.146-600q-33.853-33.5-82.5-33.5-48.646 0-82.146 33.854-33.5 33.853-33.5 82.5Q364-468.5 397.854-435q33.853 33.5 82.5 33.5ZM206.5-218h547V-214.111q-60-54.389-129.839-81.389-69.838-27-143.661-27-72 0-141.5 26.5t-132 79.889V-218Zm273.441-201q-40.233 0-69.337-29.163-29.104-29.163-29.104-69.396 0-40.233 29.163-69.337Q439.826-616 480.059-616q40.233 0 69.337 29.163 29.104 29.163 29.104 69.396 0 40.233-29.163 69.337Q520.174-419 479.941-419ZM480-541.5Z"
        fill="var(--svgIconColor)"
        stroke="var(--svgIconColor)"
      />
    </svg>
  );
};

export default AssignmentInd;
