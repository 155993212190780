import React from "react";

const TestKnowledge = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      viewBox="0 -960 960 960"
      width="40"
      fill="var(--svgIconColor)"
    >
      <path d="M285.737-410.5q9.263 0 16.513-7.088 7.25-7.087 7.25-16 0-8.912-6.987-16.412-6.987-7.5-16.25-7.5t-16.513 7.189q-7.25 7.188-7.25 16.25 0 9.061 6.987 16.311t16.25 7.25Zm0-118.5q9.263 0 16.513-7.088 7.25-7.087 7.25-16 0-8.912-6.987-16.412-6.987-7.5-16.25-7.5t-16.513 7.189q-7.25 7.188-7.25 16.25 0 9.061 6.987 16.311t16.25 7.25Zm0-118.5q9.263 0 16.513-7.088 7.25-7.087 7.25-16 0-8.912-6.987-16.412-6.987-7.5-16.25-7.5t-16.513 7.189q-7.25 7.188-7.25 16.25 0 9.061 6.987 16.311t16.25 7.25ZM397-425h199v-17.5H397v17.5Zm0-118.5h294.5V-561H397v17.5Zm0-118.5h294.5v-17.5H397v17.5ZM153-203.5v-554.562q0-21.076 14.181-35.257Q181.363-807.5 202.28-807.5h555.44q20.918 0 35.099 14.181Q807-779.138 807-758.198v412.396q0 20.94-14.181 35.121-14.181 14.181-35.097 14.181H246l-93 93ZM239.5-314h518q12 0 22-10t10-22v-412q0-12-10-22t-22-10h-555q-12 0-22 10t-10 22v514l69-70Zm-69 0v-476 476Z" />
    </svg>
  );
};

export default TestKnowledge;
