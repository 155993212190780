import React from "react";

const Manikin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 -960 960 960"
      width="32"
    >
      <path
        d="M480.449-405.538q20.628 0 34.551-14.372 13.923-14.372 13.923-35.001 0-20.628-14.168-34.551-14.167-13.923-35.012-13.923-20.435 0-34.551 14.168-14.115 14.168-14.115 35.012 0 20.436 14.372 34.551 14.372 14.116 35 14.116ZM359.077-289.231h241.846v-13q0-15.733-8.319-25.574-8.32-9.842-21.527-16.31-20.616-9.607-44.09-14.285-23.474-4.677-47.019-4.677-23.545 0-46.997 4.677-23.453 4.678-44.048 14.285-13.207 6.468-21.527 16.31-8.319 9.841-8.319 25.574v13Zm309.958 147.077H290.641q-22.502 0-37.802-15.45-15.301-15.451-15.301-37.919v-568.954q0-22.468 15.451-37.919 15.45-15.45 38.003-15.45h266.367l165.103 165.231v457.06q0 22.5-15.463 37.951-15.462 15.45-37.964 15.45Zm-.112-28.923q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-445.096l-148.01-148.135H291.077q-9.231 0-16.923 7.692-7.692 7.692-7.692 16.923v568.616q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692h377.846Zm-402.461 0v-617.846 617.846Z"
        fill="var(--svgIconColor)"
        stroke="var(--svgIconColor)"
      />
    </svg>
  );
};

export default Manikin;
