import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./sysadminlogin.module.scss";
import med from "../../assets/images/login/med.png";
import airflowManikinImg from "../../assets/images/login/slide.png";
import operation from "../../assets/images/login/slide1.jpg";
import backgroundimage from "../../assets/images/backgroundimage.svg";
import ambulance from "../../assets/images/login/slide2.jpg";
import ClipLoader from "react-spinners/ClipLoader";
// import logo from "../../assets/images/login/medilogo.png"
import logo from "../../assets/images/login/logo_new.png";
import "./slider.css";
import { useSelector, useDispatch } from "react-redux";
function AuthContainer() {
  const loginstate = useSelector((state) => state?.loginstorestate?.loginstate);
  
  useEffect(() => {
    if(localStorage.theme === "light"){
      let anyv = document.documentElement.classList.toggle(
        "dark",
        false
      );
      console.log(anyv)
    }
    
  })
  const [loaderColor, setLoaderColor] = useState(localStorage.theme === "light" ? " #000000" : "#ffffff")
  if (loginstate) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: `${
            localStorage.theme === "light" ? "#ffffff" : " var(--bg-primary)"
          }`,
        }}
      >
        <ClipLoader
          size={80}
          aria-label="Loading Spinner"
          data-testid="loader"
          color={loaderColor}
        />
      </div>
    );
  }
  return (
    <div
      id={styles.auth_cont}
      // style={{
      //   background: localStorage.theme === "dark" ? "linear-gradient(180deg, #222A35 3%, rgba(1,1,1,1) 40%)" : "white",
      //   color: localStorage.theme === "dark" ? "var(--primary-color)" : "black",
      // }}
      className="text-textColor bg-white dark:bg-custom-gradient"
    >
      <div className={styles.auth_subcontainer}>
        <div className="relative">
          {/* text */}
          <div className={styles.tagline_cont}>
            <div className={styles.top_box}>
              <p className={styles.com_title}>DRISH</p>
              <div className={styles.box1}>for</div>
            </div>
            <div className={styles.box2}>NextGen HealthCare</div>
            <span className={styles.logotext}>Training</span>
          </div>
          {/* <div className="slider-container">
            <div className="slide fade">
              <img src={ambulance} alt="med_bg" />
            </div>
            <div className="slide fade">
              <img src={airflowManikinImg} alt="med_bg" />
            </div>
            <div className="slide fade">
              <img src={med} alt="med_bg" />
            </div>

            <div className="slide fade">
              <img src={operation} alt="operation_bg" />
           
          </div> */}
          <div id="_image-slider">
            {/* <div id="_image-slider"> */}
            {/* <img src={ambulance} alt="med_bg" />
            <img src={airflowManikinImg} alt="med_bg" />
            <img src={med} alt="med_bg" /> */}
            <img src={backgroundimage} alt="operation_bg" />
          </div>
        </div>
        <div className={styles.forms_parentcontainer}>
          <div className={styles.forms_parentubcontainer}>
            <div
              className={styles.company_title}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <a href="http://medtrain.ai" target="_medtrainai">
                <div className="relative right-[30px] bottom-[9px]">
                  <img
                    src={logo}
                    alt="logo"
                    width="150px"
                    height="250px"
                    id={styles.logo_img}
                    className={`dark:bg-[var(--bg-primary)] bg-transparent`}
                  />
                </div>
              </a>
              <p className="text-[var(--text-secondary-color)] text-[20px]">
                Sign In
              </p>
              {/* <span className="text-4xl font-bold">MedTrain AI</span> */}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthContainer;
