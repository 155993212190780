import axios from "axios";

export const BASE_URL = process.env?.REACT_APP_BASE_URL;

export default axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
}) 