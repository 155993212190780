export const SOCKET_DATA_HEADERS = [
  { key: "RaspTime", name: "Time" },
  { key: "AngleRotation", name: "AngleRotation" },
  { key: "TeethFSR", name: "Teeth FSR" },
  { key: "OesophagusReed", name: "Oesophagus" },
  { key: "TracheaReedStart", name: "Trachea Start" },
  { key: "TracheaReedInter1", name: "Trachea Inter 1" },
  { key: "TracheaReedInter2", name: "Trachea Inter 2" },
  { key: "TracheaReedEnd", name: "Trachea End" },
  { key: "TracheaFSR", name: "Trachea FSR" },
  { key: "LeftLungBMP", name: "Left Lung" },
  { key: "RightLungBMP", name: "Right Lung" },
];

export const SOCKET_DATA_HEADERS_CSV = [
  { label: "Time" ,key: "RaspTime" },
  { label: "Angle Rotation",key: "AngleRotation" },
  {  label: "Teeth FSR",key: "TeethFSR" },
  {label: "Oesophagus", key: "OesophagusReed" },
  { label: "Trachea Start", key: "TracheaReedStart"},
  { label: "Trachea Inter 1" ,key: "TracheaReedInter1"},
  {  label: "Trachea Inter 2" ,key: "TracheaReedInter2"},
  { label: "Trachea End", key: "TracheaReedEnd"},
  { label: "Trachea FSR",key: "TracheaFSR" },
  { label: "Left Lung" ,key: "LeftLungBMP"},
  {  label: "Right Lung" ,key: "RightLungBMP"},
];
