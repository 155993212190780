import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaUserPlus } from 'react-icons/fa';
import { VscOrganization } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { getManikins } from '../../../api/device.rest';
import { mapManikinToOrg, mapManikinToOrgUser } from '../../../api/manikin.rest';
import { getOrganizations } from '../../../api/org.rest';
import { getAllUsers } from '../../../api/user.rest';
import { toastSeverity } from '../../../components/toastr';
import { hideAlert, showAlert } from '../../../store/alert.slice';

function Mapping() {
  const [tableData, setTableData] = useState([]);
  const [showAlcToUserModal, setShowAlcToUserModal] = useState(false);
  const [showAlcToOrgModal, setShowAlcToOrgModal] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [allUserData, setAllUserData] = useState([]);

  const { register, handleSubmit, errors, reset, watch } = useForm();

  const watchAllFields = watch();

  const getOrgs = async () => {
    try {
      const resp = await getOrganizations();
      setOrgs(resp?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };

  const allUsers = async () => {
    try {
      const res = await getAllUsers();
      setAllUserData(res.data.data);
    } catch (ex) {
      console.log(ex);
    }
  }
  const handleUserAlcModalToggle = () => {
    if (!showAlcToUserModal) {
      getOrgs();
      allUsers();
    } else {
      reset();
    }
    setShowAlcToUserModal(!showAlcToUserModal);
  }
  const handleOrgAlcModalToggle = () => {
    if (!showAlcToOrgModal) {
      getOrgs();
    } else {
      reset();
    }
    setShowAlcToOrgModal(!showAlcToOrgModal);
  }

  const dispatch = useDispatch();

  const getManikinTableData = async () => {
    await getManikins().then((resp) => {
      setTableData(resp?.data?.data)
    }).catch((e) => {
      console.error(e);
      dispatch(showAlert({ message: e?.response?.data?.data || "Something went wrong", severity: toastSeverity.ERROR }))
      setTimeout(() => dispatch(hideAlert()), 5000)
    })
  }

  const handleManikinAllocationToUser = async (data) => {
    await mapManikinToOrgUser(data?.orgId, { ...data, access_date: new Date().toISOString().split("T")[0] }).then((resp) => {
      getManikinTableData();
      setShowAlcToUserModal(false);
      dispatch(showAlert({ message: "Manikin Allocated", severity: toastSeverity.SUCCESS }))
      setTimeout(() => dispatch(hideAlert()), 5000)
    }).catch((e) => {
      console.error(e);
      dispatch(showAlert({ message: JSON.stringify(e?.response?.data?.data) || "Something went wrong", severity: toastSeverity.ERROR }))
      setTimeout(() => dispatch(hideAlert()), 5000)
    })
  }


  const handleManikinAllocationToOrganization = async (data) => {
    await mapManikinToOrg(data?.orgId, data?.mnk_id).then((resp) => {
      getManikinTableData();
      setShowAlcToOrgModal(false);
      dispatch(showAlert({ message: "Manikin allocated Successfully", severity: toastSeverity.SUCCESS }))
      setTimeout(() => dispatch(hideAlert()), 5000)
    }).catch((e) => {
      console.error(e);
      dispatch(showAlert({ message: JSON.stringify(e?.response?.data?.data) || "Something went wrong", severity: toastSeverity.ERROR }))
      setTimeout(() => dispatch(hideAlert()), 5000)
    })
  }

  useEffect(() => {
    getManikinTableData()
  }, [])


  return (
    <>
      <div className="box-header-sec">
        <h1 className="box-header">All Manikin Mapping</h1>
        <div className="d-flex flex-row align-items-center gap-2">
          <button className="btn-submit fs-14 w-auto" onClick={handleUserAlcModalToggle}>Allocate to User</button>
          <button className="btn-submit fs-14 w-auto" onClick={handleOrgAlcModalToggle}>Allocate to Organization</button>
        </div>
      </div>
      <div className='table-style'>
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Device Name</th>
              <th>Device Status</th>
              <th>Assigned Organization</th>
              <th>User Allocated</th>
              <th>Created Date</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {
              !!tableData && Array.isArray(tableData) && tableData?.length > 0 ?
                tableData?.map((item) => {
                  return <tr>
                    <td>{item?.device_id}</td>
                    <td>{item?.device_name || "-"}</td>
                    <td><span className={`badge ${item?.status === "Active" ? "bg-success" : item?.status === "Deactive" ? "bg-danger" : "bg-warning"}`}>{item?.status}</span></td>
                    <td>{item?.org_allocate?.organization?.org_nm || "-"}</td>
                    <td>{Array.isArray(item?.user_allocate) && item?.user_allocate?.length > 0 ? item?.user_allocate?.map((e) => e?.user?.name)?.join(", ") : "-"}</td>
                    <td>{new Date(item?.rec_ins_date)?.toLocaleDateString() || "-"}</td>
                  </tr>
                }) :
                <tr>
                  <td colSpan={7} className="text-center">No Data Available</td>
                </tr>
            }
          </tbody>
        </table>
      </div>
      {showAlcToUserModal ?
        <Modal show={showAlcToUserModal} centered>
          <Modal.Body>
            <div className="box-header-sec">
              <h1 className="box-header mb-3">Allocate Manikin to User</h1>
            </div>
            <form onSubmit={handleSubmit(handleManikinAllocationToUser)}>
              <div className="box-style">
                <div className="row mb-3">
                  <label htmlFor="orgId" className="input-label">Organization</label>
                  <select name="orgId" id="orgId" className="textfield-style"
                    placeholder='Select Organization'
                    {...register("orgId", { required: true })}
                  >
                    <option value="" disabled selected>Select Organization</option>
                    {!!orgs && Array.isArray(orgs) && orgs?.length > 0 ?
                      orgs?.map((item) => <option key={item?.org_id} value={item?.org_id}>{item?.org_nm}</option>)
                      : <></>}
                  </select>
                </div>
                <div className="row mb-3">
                  <label htmlFor="uid" className="input-label">User</label>
                  <select name="uid" id="uid" className="textfield-style"
                    placeholder='Select Organization'
                    {...register("uid", { required: true })}
                  >
                    <option value="" disabled selected>Select User</option>
                    {!!allUserData && Array.isArray(allUserData) && allUserData?.length > 0 ?
                      allUserData/* ?.filter((i) => !!i?.org_id && i?.org_id === watchAllFields?.orgId) */?.map((item) => <option key={item?.uid} value={item?.uid}>{item?.name}</option>)

                      : <></>}
                  </select>
                </div>
                <div className="row mb-3">
                  <label htmlFor="mnk_id" className="input-label">Device Name</label>
                  <select name="mnk_id" id="mnk_id" className="textfield-style"
                    placeholder='Select Manikin'
                    {...register("mnk_id", { required: true })}
                  >
                    <option value="" disabled selected>Select Manikin</option>
                    {!!tableData && Array.isArray(tableData) && tableData?.length > 0 ?
                      tableData/* ?.filter((i) => !!i?.org_id && i?.org_id === watchAllFields?.orgId) */?.map((item) => <option key={item?.device_id} value={item?.device_id}>{item?.device_name || "-"} - {item?.device_id}</option>)

                      : <></>}
                  </select>
                </div>
                <div className="row mt-3 d-flex justify-content-end align-items-center gap-1">

                  <button type='reset' className="btn-outline-cancel fs-12 w-auto" onClick={handleUserAlcModalToggle}>
                    Close
                  </button>
                  <button type='submit' className="btn-submit fs-12 w-auto" >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal> : <></>
      }
      {showAlcToOrgModal ?
        <Modal show={showAlcToOrgModal} centered>
          <Modal.Body>
            <div className="box-header-sec">
              <h1 className="box-header mb-3">Allocate Manikin to Organization</h1>
            </div>
            <form onSubmit={handleSubmit(handleManikinAllocationToOrganization)}>
              <div className="box-style">
                <div className="row mb-3">
                  <label htmlFor="orgId" className="input-label">Organization</label>
                  <select name="orgId" id="orgId" className="textfield-style"
                    placeholder='Select Organization'
                    {...register("orgId", { required: true })}
                  >
                    <option value="" disabled selected>Select Organization</option>
                    {!!orgs && Array.isArray(orgs) && orgs?.length > 0 ?
                      orgs?.map((item) => <option key={item?.org_id} value={item?.org_id}>{item?.org_nm}</option>)
                      : <></>}
                  </select>
                </div>
                <div className="row mb-3">
                  <label htmlFor="mnk_id" className="input-label">Device Name</label>
                  <select name="mnk_id" id="mnk_id" className="textfield-style"
                    placeholder='Select Manikin'
                    {...register("mnk_id", { required: true })}
                  >
                    <option value="" disabled selected>Select Manikin</option>
                    {!!tableData && Array.isArray(tableData) && tableData?.length > 0 ?
                      tableData/* ?.filter((i) => !!i?.org_id && i?.org_id === watchAllFields?.orgId) */?.map((item) => <option key={item?.device_id} value={item?.device_id}>{item?.device_name || "-"} - {item?.device_id}</option>)

                      : <></>}
                  </select>
                </div>
                <div className="row mt-3 d-flex justify-content-end align-items-center gap-1">

                  <button type='reset' className="btn-outline-cancel fs-12 w-auto" onClick={handleOrgAlcModalToggle}>
                    Close
                  </button>
                  <button type='submit' className="btn-submit fs-12 w-auto" >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal> : <></>
      }

    </>
  )
}

export default Mapping;