import React from "react";

function RplayGreenIcon() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 213 224"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="106.5" cy="118" rx="106.5" ry="106" fill="#45A708" />
      <path
        d="M205.484 84.2297C90.701 -29.107 17.484 90.2412 17.484 90.2412C17.484 90.2412 19.1917 21.2644 102.569 17.7119C159.176 24.3912 179.384 40.8727 205.484 84.2297Z"
        fill="#48D31E"
      />
      <path
        d="M149.656 118.064L83.8371 164.025L84.985 70.501L149.656 118.064Z"
        fill="white"
      />
    </svg>
  );
}

export default RplayGreenIcon;
