import React from "react";

const ClinicalNotes = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      viewBox="0 -960 960 960"
      width="40"
      fill="var(--svgIconColor)"
    >
      <path d="M667.355-359.846q-37.624 0-64.182-26.539t-26.558-64.452q0-37.432 26.539-63.99t64.452-26.558q37.433 0 63.99 26.587 26.558 26.587 26.558 64.212 0 37.624-26.587 64.182t-64.212 26.558Zm-.014-28.923q25.867 0 43.878-17.968 18.012-17.968 18.012-43.835T711.21-494.45q-18.02-18.012-43.589-18.012-26.059 0-44.071 18.021-18.011 18.021-18.011 43.589 0 26.06 17.967 44.071 17.968 18.012 43.835 18.012ZM456.615-116.692v-87.235q0-13.549 6.501-25.484 6.501-11.936 17.947-19.206 27.26-16.121 56.725-26.43 29.465-10.308 60.327-15.761l69.462 89.385 68.692-89.385q31.189 5.453 60.93 15.727 29.741 10.274 57.916 26.504 11.154 6.966 16.712 18.455 5.558 11.489 6.327 24.728v88.702H456.615Zm29.424-28.923h188.653l-88.423-112.731q-27.237 6.471-52.926 16.274-25.689 9.803-47.304 23.995v72.462Zm174.038 0h189.154v-72.495q-23.025-13.29-49.048-23.244-26.023-9.953-52.741-16.223l-87.365 111.962Zm14.615 0Zm-14.615 0Zm-416.882-44.539q-22.695 0-38.176-15.727-15.481-15.727-15.481-37.811v-473.231q0-22.085 15.727-37.812 15.727-15.727 37.812-15.727h473.846q22.085 0 37.812 15.727 15.727 15.727 15.727 37.812v105.116q-6.962-5.193-14.981-6.674-8.019-1.481-13.943-8.904v-89.538q0-10.769-6.923-17.692t-17.692-6.923H243.077q-10.769 0-17.692 6.923t-6.923 17.692v473.231q0 10.769 6.923 17.692t17.692 6.923h117q-2.192 3.077-2.673 7.013-.481 3.936-.481 7.23v14.68H243.195Zm67.882-420.615h264.577q14.461-12.808 33.442-19.904t39.827-8.866v-.154H311.077v28.924Zm0 144.923h170.192q-1.461-6.885-1.115-14.327.346-7.442.615-14.596H311.077v28.923Zm0 144.923h100.308q14.673-11.978 31.38-20.478 16.707-8.499 34.62-15.33l-.616 6.885H311.077v28.923Zm-92.615 101.846v-522.461V-626.5v-14.577 422Zm449.115-231.731Z" />
    </svg>
  );
};

export default ClinicalNotes;
